export const iconSynonyms = {
    abc: "alphabet character font letter symbol text type",
    access_alarm: "clock time",
    access_alarms: "clock time",
    accessibility: "accessible body handicap help human people person user",
    accessibility_new: "accessible arms body handicap help human people person user",
    accessible: "accessibility body handicap help human people person user wheelchair",
    accessible_forward: "accessibility body handicap help human people person wheelchair",
    access_time: "clock time",
    account_balance: "bank bill building card cash coin commerce court credit currency dollars finance money online payment structure temple transaction",
    account_balance_wallet: "bank bill card cash coin commerce credit currency dollars finance money online payment transaction",
    account_box: "avatar face human people person profile square thumbnail user",
    account_circle: "avatar face human people person profile thumbnail user",
    account_tree: "analytics chart connect data diagram flow infographic measure metrics process project sitemap square statistics structure tracking",
    ac_unit: "air cold conditioner freeze snowflake temperature weather winter",
    adb: "android bridge debug",
    add: "+ create item new plus symbol",
    add_alarm: "clock plus time",
    add_alert: "+ active alarm announcement bell callout chime information new notifications notify plus reminder ring sound symbol",
    add_a_photo: "+ camera lens new photography picture plus symbol",
    add_box: "create new plus square symbol",
    add_business: "+ bill building card cash coin commerce company credit currency dollars market money new online payment plus retail shopping storefront symbol",
    add_card: "+ bill cash coin commerce cost credit currency dollars finance money new online payment plus price shopping symbol",
    add_chart: "+ analytics bars data diagram infographic measure metrics new plus statistics symbol tracking",
    add_circle: "+ create new plus",
    add_circle_outline: "+ create new plus",
    add_comment: "+ bubble chat communicate feedback message new plus speech symbol",
    add_ic_call: "+ cell contact device hardware mobile new plus symbol telephone",
    add_link: "attach clip new plus symbol",
    add_location: "+ destination direction gps maps new pin place plus stop symbol",
    add_location_alt: "+ destination direction maps new pin place plus stop symbol",
    add_moderator: "+ certified new plus privacy private protection security shield symbol verified",
    add_photo_alternate: "+ image landscape mountains new photography picture plus symbol",
    add_road: "+ destination direction highway maps new plus stop street symbol traffic",
    add_shopping_cart: "card cash checkout coin commerce credit currency dollars money online payment plus",
    add_task: "+ approve check circle completed increase mark ok plus select tick yes",
    add_to_drive: "+ app backup cloud data files folders gdrive google plus recovery storage",
    add_to_home_screen: "Android add arrow cell device hardware iOS mobile phone tablet to up",
    add_to_photos: "collection image landscape mountains photography picture plus",
    add_to_queue: "+ Android backlog chrome desktop device display hardware iOS lineup mac monitor new plus screen symbol television watch web window",
    adf_scanner: "document feeder machine office",
    adjust: "alter center circles control dot edit filter fix image mix move setting slider sort switch target tune",
    admin_panel_settings: "account avatar certified face human people person privacy private profile protection security shield user verified",
    ad_units: "Android banner cell device hardware iOS mobile notifications phone tablet top",
    agriculture: "automobile cars cultivation farm harvest maps tractor transport travel truck vehicle",
    air: "blowing breeze flow wave weather wind",
    airlines: "airplane airport flight transportation travel trip",
    airline_seat_flat: "bed body business class first human people person rest sleep travel",
    airline_seat_flat_angled: "bed body business class first human people person rest sleep travel",
    airline_seat_individual_suite: "bed body business class first human people person rest sleep travel",
    airline_seat_legroom_extra: "body feet human people person sitting space travel",
    airline_seat_legroom_normal: "body feet human people person sitting space travel",
    airline_seat_legroom_reduced: "body feet human people person sitting space travel",
    airline_seat_recline_extra: "body feet human legroom people person sitting space travel",
    airline_seat_recline_normal: "body extra feet human legroom people person sitting space travel",
    airline_stops: "arrow destination direction layover location maps place transportation travel trip",
    airplanemode_active: "flight flying on signal",
    airplanemode_inactive: "airport disabled enabled flight flying maps offline slash transportation travel",
    airplane_ticket: "airport boarding flight fly maps pass transportation travel",
    airplay: "apple arrow cast connect control desktop device display monitor screen signal television tv",
    airport_shuttle: "automobile bus cars commercial delivery direction maps mini public transportation travel truck van vehicle",
    alarm: "alart bell clock countdown date notification schedule time",
    alarm_add: "+ alart bell clock countdown date new notification plus schedule symbol time",
    alarm_off: "alart bell clock disabled duration enabled notification slash stop timer watch",
    alarm_on: "alart bell checkmark clock disabled duration enabled notification off ready slash start timer watch",
    album: "artist audio bvb cd computer data disk file music play record sound storage track vinyl",
    align_horizontal_center: "alignment format layout lines paragraph rules style text",
    align_horizontal_left: "alignment format layout lines paragraph rules style text",
    align_horizontal_right: "alignment format layout lines paragraph rules style text",
    align_vertical_bottom: "alignment format layout lines paragraph rules style text",
    align_vertical_center: "alignment format layout lines paragraph rules style text",
    align_vertical_top: "alignment format layout lines paragraph rules style text",
    all_inbox: "Inbox delivered delivery email letter message post send",
    all_inclusive: "endless forever infinite infinity loop mobius neverending strip sustainability sustainable",
    all_out: "arrows circle directional expand shape",
    alternate_email: "@ address contact tag",
    alt_route: "alternate alternative arrows direction maps navigation options other routes split symbol",
    analytics: "assessment bar chart data diagram infographic measure metrics statistics tracking",
    anchor: "google logo",
    android: "brand character logo mascot operating system toy",
    animation: "circles film motion movement movie moving sequence video",
    announcement: "! alert attention balloon bubble caution chat comment communicate danger error exclamation feedback important mark message news notification speech symbol warning",
    aod: "Android always device display hardware homescreen iOS mobile phone tablet",
    apartment: "accommodation architecture building city company estate flat home house office places real residence residential shelter units workplace",
    api: "developer development enterprise software",
    app_blocking: "Android applications cancel cell device hardware iOS mobile phone stopped tablet",
    app_registration: "apps edit pencil register",
    approval: "apply approvals approve certificate certification disapproval drive file impression ink mark postage stamp",
    apps: "all applications circles collection components dots grid homescreen icons interface squares ui ux",
    app_settings_alt: "Android applications cell device gear hardware iOS mobile phone tablet",
    app_shortcut: "bookmarked favorite highlight important mobile saved software special star",
    apps_outage: "all applications circles collection components dots grid interface squares ui ux",
    architecture: "art compass design drawing engineering geometric tool",
    archive: "inbox mail store",
    arrow_back: "application components direction interface left navigation previous screen ui ux website",
    arrow_back_ios: "application chevron components direction interface left navigation previous screen ui ux website",
    arrow_back_ios_new: "application chevron components direction interface left navigation previous screen ui ux website",
    arrow_circle_down: "direction navigation",
    arrow_circle_up: "direction navigation",
    arrow_downward: "application components direction interface navigation screen ui ux website",
    arrow_drop_down: "application components direction interface navigation screen ui ux website",
    arrow_drop_down_circle: "application components direction interface navigation screen ui ux website",
    arrow_drop_up: "application components direction interface navigation screen ui ux website",
    arrow_forward: "application arrows components direction interface navigation right screen ui ux website",
    arrow_forward_ios: "application chevron components direction interface navigation next right screen ui ux website",
    arrow_left: "application backstack backward components direction interface navigation previous screen ui ux website",
    arrow_right: "application components continue direction forward interface navigation screen ui ux website",
    arrow_right_alt: "arrows direction east navigation pointing shape",
    arrow_upward: "application components direction interface navigation screen submit ui ux website",
    article: "clarify document file news page paper text writing",
    art_track: "album artist audio display format image insert music photography picture sound tracks",
    aspect_ratio: "expand image monitor resize resolution scale screen square",
    assessment: "analytics bars chart data diagram infographic measure metrics report statistics tracking",
    assignment: "article clipboard document task text writing",
    assignment_ind: "account clipboard document face people person profile task user",
    assignment_late: "! alert announcement attention caution clipboard danger document error exclamation important mark notification symbol task warning",
    assignment_return: "arrow back clipboard document left point retun task",
    assignment_returned: "arrow clipboard document down point task",
    assignment_turned_in: "approve checkmark clipboard complete document done finished ok select task tick validate verified yes",
    assistant: "bubble chat comment communicate feedback message recommendation speech star suggestion twinkle",
    assistant_direction: "destination location maps navigate navigation pin place right stop",
    assistant_photo: "flag recommendation smart star suggestion",
    assured_workload: "compliance confidential federal government regulatory secure sensitive",
    atm: "alphabet automated bill card cart cash character coin commerce credit currency dollars font letter machine money online payment shopping symbol teller text type",
    attach_email: "attachment clip compose envelop letter link message send",
    attach_file: "add item link mail media paperclip",
    attachment: "compose file image item link paperclip",
    attach_money: "bill card cash coin commerce cost credit currency dollars finance online payment price profit sale symbol",
    attractions: "amusement entertainment ferris fun maps park places wheel",
    attribution: "attribute body copyright copywriter human people person",
    audio_file: "document key music note sound track",
    audiotrack: "key music note sound",
    auto_awesome: "adjust editing enhance filter image photography photos setting stars",
    auto_awesome_mosaic: "adjust collage editing enhance filter grid image layout photographs photography photos pictures setting",
    auto_awesome_motion: "adjust animation collage editing enhance filter image live photographs photography photos pictures setting video",
    auto_delete: "bin can clock date garbage remove schedule time trash",
    auto_fix_high: "adjust editing enhance erase magic modify pen stars tool wand",
    auto_fix_normal: "edit erase magic modify stars wand",
    auto_fix_off: "disabled edit enabled erase magic modify on slash stars wand",
    autofps_select: "A alphabet character font frame frequency letter per rate seconds symbol text type",
    auto_graph: "analytics chart data diagram infographic line measure metrics stars statistics tracking",
    auto_mode: "around arrows direction inprogress loading navigation nest refresh renew rotate turn",
    autorenew: "around arrows cached direction inprogress loader loading navigation pending refresh rotate status turn",
    auto_stories: "audiobook flipping pages reading story",
    av_timer: "clock countdown duration minutes seconds stopwatch",
    baby_changing_station: "babies bathroom body children father human infant kids mother newborn people person toddler wc young",
    backpack: "bookbag knapsack storage travel",
    backspace: "arrow cancel clear correct delete erase remove",
    backup: "arrow cloud data drive files folders point storage submit upload",
    backup_table: "drive files folders format layout stack storage",
    badge: "account avatar card certified employee face human identification name people person profile security user work",
    bakery_dining: "bread breakfast brunch croissant food",
    balance: "equal equilibrium equity impartiality justice parity stability. steadiness symmetry",
    balcony: "architecture doors estate home house maps outside place real residence residential stay terrace window",
    ballot: "bullet bulllet election list point poll vote",
    bar_chart: "analytics anlytics data diagram infographic measure metrics statistics tracking",
    batch_prediction: "bulb idea light",
    bathroom: "closet home house place plumbing shower sprinkler wash water wc",
    bathtub: "bathing bathroom clean home hotel human person shower travel",
    battery_alert: "! attention caution cell charge danger error exclamation important mark mobile notification power symbol warning",
    battery_charging_full: "cell charge lightening lightning mobile power thunderbolt",
    battery_full: "cell charge mobile power",
    battery_saver: "+ add charge charging new plus power symbol",
    battery_std: "cell charge mobile plus power standard",
    battery_unknown: "? assistance cell charge help information mark mobile power punctuation question support symbol",
    beach_access: "parasol places summer sunny umbrella",
    bed: "bedroom double full furniture home hotel house king night pillows queen rest size sleep",
    bedroom_baby: "babies children home horse house infant kid newborn rocking toddler young",
    bedroom_child: "children furniture home hotel house kid night pillows rest size sleep twin young",
    bedroom_parent: "double full furniture home hotel house king master night pillows queen rest sizem sleep",
    bedtime: "nightime sleep",
    bedtime_off: "nightime sleep",
    beenhere: "approve archive bookmark checkmark complete done favorite label library reading remember ribbon save select tag tick validate verified yes",
    bento: "box dinner food lunch meal restaurant takeout",
    bike_scooter: "automobile cars maps transportation vehicle vespa",
    biotech: "chemistry laboratory microscope research science technology test",
    blender: "appliance cooking electric juicer kitchen machine vitamix",
    blinds_closed: "cover curtains nest shutter sunshade",
    block: "allowed avoid banned cancel close disable entry exit not prohibited quit remove stop",
    bloodtype: "donate droplet emergency hospital medicine negative positive water",
    bluetooth: "cast connection device network paring streaming symbol wireless",
    bluetooth_audio: "connection device music signal sound symbol",
    bluetooth_connected: "cast connection device network paring streaming symbol wireless",
    bluetooth_disabled: "cast connection device enabled network offline paring slash streaming symbol wireless",
    bluetooth_drive: "automobile cars cast connection device maps paring streaming symbol transportation travel vehicle wireless",
    bluetooth_searching: "connection device network paring symbol wireless",
    blur_circular: "circle dots editing effect enhance filter",
    blur_linear: "dots editing effect enhance filter",
    blur_off: "disabled dots editing effect enabled enhance on slash",
    blur_on: "disabled dots editing effect enabled enhance filter off slash",
    bolt: "electric energy fast flash lightning power thunderbolt",
    book: "blog bookmark favorite label library reading remember ribbon save tag",
    bookmark: "archive favorite follow label library reading remember ribbon save tag",
    bookmark_add: "+ favorite plus remember ribbon save symbol",
    bookmark_added: "approve check complete done favorite remember save select tick validate verified yes",
    bookmark_border: "archive favorite label library outline reading remember ribbon save tag",
    bookmark_remove: "delete favorite minus remember ribbon save subtract",
    bookmarks: "favorite label layers library multiple reading remember ribbon save stack tag",
    book_online: "Android admission appointment cell device event hardware iOS mobile pass phone reservation tablet ticket",
    border_all: "doc editing editor spreadsheet stroke text type writing",
    border_bottom: "doc editing editor spreadsheet stroke text type writing",
    border_clear: "doc editing editor spreadsheet stroke text type writing",
    border_color: "all create doc editing editor marker pencil spreadsheet stroke text type writing",
    border_horizontal: "doc editing editor spreadsheet stroke text type writing",
    border_inner: "doc editing editor spreadsheet stroke text type writing",
    border_left: "doc editing editor spreadsheet stroke text type writing",
    border_outer: "doc editing editor spreadsheet stroke text type writing",
    border_right: "doc editing editor spreadsheet stroke text type writing",
    border_style: "color doc editing editor spreadsheet stroke text type writing",
    border_top: "doc editing editor spreadsheet stroke text type writing",
    border_vertical: "doc editing editor spreadsheet stroke text type writing",
    boy: "body gender human male people person social symbol",
    branding_watermark: "components copyright design emblem format identity interface layout logo screen stamp ui ux website window",
    breakfast_dining: "bakery bread butter food toast",
    brightness_1: "circle control crescent cresent level moon screen",
    brightness_2: "circle control crescent cresent level moon night screen",
    brightness_3: "circle control crescent cresent level moon night screen",
    brightness_4: "circle control crescent cresent dark level moon night screen sun",
    brightness_5: "circle control crescent cresent level moon screen sun",
    brightness_6: "circle control crescent cresent level moon screen sun",
    brightness_7: "circle control crescent cresent level light moon screen sun",
    brightness_auto: "A control display level mobile monitor phone screen",
    brightness_high: "auto control mobile monitor phone",
    brightness_low: "auto control mobile monitor phone",
    brightness_medium: "auto control mobile monitor phone",
    broken_image: "corrupt error landscape mountains photography picture torn",
    browser_not_supported: "disabled enabled internet off on page screen slash website www",
    browser_updated: "Android arrow chrome desktop device display download hardware iOS mac monitor screen web window",
    brunch_dining: "breakfast champagne champaign drink food lunch meal",
    brush: "art design draw editing painting tool",
    bubble_chart: "analytics bars data diagram infographic measure metrics statistics tracking",
    bug_report: "animal file fix insect issue problem testing ticket virus warning",
    build: "adjust fix repair spanner tool wrench",
    build_circle: "adjust fix repair tool wrench",
    bungalow: "architecture cottage estate home house maps place real residence residential stay traveling",
    burst_mode: "image landscape mountains multiple photography picture",
    bus_alert: "! attention automobile cars caution danger error exclamation important maps mark notification symbol transportation vehicle warning",
    business: "address apartment architecture building company estate flat home office place real residence residential shelter structure",
    business_center: "baggage briefcase places purse suitcase work",
    cabin: "architecture camping cottage estate home house log maps place real residence residential stay traveling wood",
    cable: "connection device electronics usb wire",
    cached: "around arrows inprogress loader loading refresh reload renew rotate",
    cake: "baked birthday candles celebration dessert food frosting party pastries pastry pie social sweet",
    calculate: "+ - = calculator count finance math",
    calendar_today: "date event month remember reminder schedule week",
    calendar_view_day: "date event format grid layout month remember reminder schedule today week",
    calendar_view_month: "date event format grid layout schedule today",
    calendar_view_week: "date event format grid layout month schedule today",
    call: "cell contact device hardware mobile talk telephone",
    call_end: "cell contact device hardware mobile talk telephone",
    call_made: "arrow device mobile",
    call_merge: "arrow device mobile",
    call_missed: "arrow device mobile",
    call_missed_outgoing: "arrow device mobile",
    call_received: "arrow device mobile",
    call_split: "arrow device mobile",
    call_to_action: "alert bar components cta design information interface layout message notification screen ui ux website window",
    camera: "album aperture lens photography picture record screenshot shutter",
    camera_alt: "image photography picture",
    camera_enhance: "important lens photography picture quality special star",
    camera_front: "body human lens mobile person phone photography portrait selfie",
    camera_indoor: "architecture building estate filming home house image inside motion nest picture place real residence residential shelter videography",
    camera_outdoor: "architecture building estate filming home house image motion nest outside picture place real residence residential shelter videography",
    camera_rear: "front lens mobile phone photography picture portrait selfie",
    camera_roll: "film image library photography",
    cameraswitch: "arrows flip rotate swap view",
    campaign: "alert announcement loud megaphone microphone notification speaker",
    cancel: "circle close cross disable exit status stop",
    cancel_presentation: "close device exit no quit remove screen share slide stop website window",
    cancel_schedule_send: "email no quit remove share stop x",
    candlestick_chart: "analytics data diagram finance infographic measure metrics statistics tracking",
    card_giftcard: "account balance bill cart cash certificate coin commerce creditcard currency dollars money online payment present shopping",
    card_membership: "bill bookmark cash certificate coin commerce cost creditcard currency dollars finance loyalty money online payment shopping subscription",
    card_travel: "bill cash coin commerce cost creditcard currency dollars finance membership miles money online payment trip",
    carpenter: "building construction cutting handyman repair saw tool",
    car_rental: "automobile cars key maps transportation vehicle",
    car_repair: "automobile cars maps transportation vehicle",
    cases: "baggage briefcase business purse suitcase",
    casino: "dice dots entertainment gamble gambling games luck places",
    cast: "Android airplay chromecast connect desktop device display hardware iOS mac monitor screencast streaming television tv web window wireless",
    cast_connected: "Android airplay chromecast desktop device display hardware iOS mac monitor screencast streaming television tv web window wireless",
    cast_for_education: "Android airplay chrome connect desktop device display hardware iOS learning lessons mac monitor screencast streaming teaching television tv web window wireless",
    category: "categories circle collection items product sort square triangle",
    celebration: "activity birthday event fun party",
    cell_tower: "broadcast casting network signal transmitting wireless",
    cell_wifi: "connection data internet mobile network phone service signal wireless",
    center_focus_strong: "camera image lens photography zoom",
    center_focus_weak: "camera image lens photography zoom",
    chair: "comfort couch decoration furniture home house living lounging loveseat room seating sofa",
    chair_alt: "cahir furniture home house kitchen lounging seating table",
    chalet: "architecture cottage estate home house maps place real residence residential stay traveling",
    change_circle: "around arrows direction navigation rotate",
    change_history: "shape triangle",
    charging_station: "Android battery cell device electric hardware iOS lightning mobile phone tablet thunderbolt",
    chat: "bubble comment communicate feedback message speech talk text",
    chat_bubble: "comment communicate feedback message speech talk text",
    chat_bubble_outline: "comment communicate feedback message speech talk text",
    check: "checkmark complete confirm correct done enter okay purchased select success tick yes",
    check_box: "approved button checkmark component control form ok selected selection square success tick toggle ui yes",
    check_box_outline_blank: "button checkmark component control deselected empty form selection square tick toggle ui",
    check_circle: "approve checkmark complete done download finished ok select success tick upload validate verified yes",
    check_circle_outline: "approve checkmark complete done finished ok select success tick validate verified yes",
    checkroom: "check closet clothes coat hanger",
    chevron_left: "arrows back direction triangle",
    chevron_right: "arrows direction forward triangle",
    child_care: "babies baby children face infant kids newborn toddler young",
    child_friendly: "baby care carriage children infant kid newborn stroller toddler young",
    chrome_reader_mode: "text",
    circle: "bullet button dot full geometry moon period radio",
    circle_notifications: "active alarm alert bell chime notify reminder ring sound",
    class: "archive bookmark category favorite item label library reading remember ribbon save tag",
    clean_hands: "bacteria disinfect germs gesture sanitizer",
    cleaning_services: "dust sweep",
    clear: "allowed back cancel correct cross delete disable erase exit not times",
    clear_all: "delete document erase format lines list notifications wipe",
    close: "allowed cancel cross disable exit not status stop times",
    closed_caption: "accessible alphabet character decoder font language letter media movies subtitles symbol text tv type",
    closed_caption_disabled: "accessible alphabet character decoder enabled font language letter media movies off slash subtitles symbol text tv type",
    closed_caption_off: "accessible alphabet character decoder font language letter media movies outline subtitles symbol text tv type",
    close_fullscreen: "action arrows collapse direction minimize",
    cloud: "connection internet network sky upload weather",
    cloud_circle: "application backup connection drive files folders internet network sky storage upload",
    cloud_done: "application approve backup checkmark complete connection drive files folders internet network ok select sky storage tick upload validate verified yes",
    cloud_download: "application arrow backup connection drive files folders internet network sky storage upload",
    cloud_off: "application backup connection disabled drive enabled files folders internet network offline sky slash storage upload",
    cloud_queue: "connection internet network sky upload",
    cloud_sync: "application around backup connection drive files folders inprogress internet loading network refresh renew rotate sky storage turn upload",
    cloud_upload: "application arrow backup connection download drive files folders internet network sky storage",
    co2: "carbon dioxide gas",
    code: "brackets css developer engineering html parenthesis platform",
    code_off: "brackets css developer disabled enabled engineering html on platform slash",
    coffee: "beverage cup drink mug plate set tea",
    coffee_maker: "appliances beverage cup drink machine mug",
    collections: "album gallery image landscape library mountains photography picture stack",
    collections_bookmark: "album archive favorite gallery label library reading remember ribbon save stack tag",
    colorize: "color dropper extract eye picker pipette tool",
    color_lens: "art paint pallet",
    comment: "bubble chat communicate document feedback message note outline speech",
    comment_bank: "archive bookmark bubble cchat communicate favorite label library message remember ribbon save speech tag",
    comments_disabled: "bubble chat communicate enabled feedback message offline on slash speech",
    commit: "accomplish bind circle dedicate execute line perform pledge",
    commute: "automobile car direction maps public train transportation trip vehicle",
    compare: "adjustment editing edits enhance fix images photography photos scan settings",
    compare_arrows: "collide directional facing left pointing pressure push right together",
    compass_calibration: "connection internet location maps network refresh service signal wifi wireless",
    compress: "arrows collide pressure push together",
    computer: "Android chrome desktop device hardware iOS laptop mac monitor pc web window",
    confirmation_number: "admission entertainment event ticket",
    connected_tv: "Android airplay chrome desktop device display hardware iOS mac monitor screencast streaming television web window wireless",
    connecting_airports: "airplanes flight transportation travel trip",
    connect_without_contact: "communicating distance people signal socialize",
    construction: "build carpenter equipment fix hammer improvement industrial industry repair tools wrench",
    contactless: "applepay bluetooth cash connection connectivity credit device finance payment signal tap transaction wifi wireless",
    contact_mail: "account address avatar communicate email face human information message people person profile user",
    contact_page: "account avatar data document drive face folders human people person profile sheet slide storage user writing",
    contact_phone: "account avatar call communicate face human information message mobile number people person profile user",
    contacts: "account address avatar call cell face human information mobile number people person phone profile user",
    contact_support: "? alert announcement bubble chat comment communicate help information mark message punctuation speech symbol vquestion",
    content_copy: "copy document duplicate file multiple past",
    content_cut: "cut document file past scissors trim",
    content_paste: "clipboard copy cut document file multiple",
    content_paste_go: "clipboard disabled document enabled file slash",
    content_paste_off: "clipboard disabled document enabled file slash",
    content_paste_search: "clipboard document file find trace track",
    contrast: "black editing effect filter grayscale images photography pictures settings white",
    control_camera: "adjust arrows center direction left move right",
    control_point: "+ add circle plus",
    control_point_duplicate: "+ add circle multiple new plus symbol",
    co_present: "arrow co-present presentation screen share slides togather website",
    copy_all: "content cut document file multiple page paper past",
    copyright: "alphabet character circle emblem font legal letter owner symbol text",
    coronavirus: "19 bacteria covid disease germs illness sick social",
    corporate_fare: "architecture building business estate organization place real residence residential shelter",
    cottage: "architecture beach estate home house lake lodge maps place real residence residential stay traveling",
    countertops: "home house kitchen sink table",
    create: "compose editing input item new pencil write writing",
    create_new_folder: "+ add data directory document drive file plus sheet slide storage symbol",
    credit_card: "bill cash charge coin commerce cost creditcard currency dollars finance information money online payment price shopping symbol",
    credit_card_off: "charge commerce cost disabled enabled finance money online payment slash",
    credit_score: "approve bill card cash check coin commerce complete cost currency dollars done finance loan mark money ok online payment select symbol tick validate verified yes",
    crib: "babies baby bassinet bed children cradle infant kid newborn sleeping toddler",
    crop: "adjustments area editing frame images photos rectangle settings size square",
    crop_din: "adjustments area editing frame images photos picture rectangle settings size square",
    crop_free: "adjustments barcode editing focus frame image photos qrcode settings size square zoom",
    crop_landscape: "adjustments area editing frame images photos picture settings size square",
    crop_original: "adjustments area editing frame images photos picture settings size square",
    crop_portrait: "adjustments area editing frame images photos picture rectangle settings size square",
    crop_rotate: "adjustments area arrows editing frame images photos settings size turn",
    crop_square: "adjustments area editing frame images photos rectangle settings size",
    css: "alphabet brackets character code developer engineering font html letter platform symbol text type",
    currency_exchange: "360 around arrows cash coin commerce direction dollars inprogress money pay renew rotate sync turn universal",
    currency_franc: "bill card cash coin commerce cost credit dollars finance money online payment price shopping symbol",
    currency_lira: "bill card cash coin commerce cost credit dollars finance money online payment price shopping symbol",
    currency_pound: "bill card cash coin commerce cost credit dollars finance money online payment price shopping symbol",
    currency_ruble: "bill card cash coin commerce cost credit dollars finance money online payment price shopping symbol",
    currency_rupee: "bill card cash coin commerce cost credit dollars finance money online payment price shopping symbol",
    currency_yen: "bill card cash coin commerce cost credit dollars finance money online payment price shopping symbol",
    currency_yuan: "bill card cash coin commerce cost credit dollars finance money online payment price shopping symbol",
    curtains: "blinds cover nest open shutter sunshade",
    curtains_closed: "blinds cover nest shutter sunshade",
    dangerous: "broken fix no sign stop update warning wrong",
    dark_mode: "application device interface moon night silent theme ui ux website",
    dashboard: "cards format layout rectangle shapes square website",
    dashboard_customize: "cards format layout rectangle shapes square website",
    data_saver_off: "analytics bars chart diagram donut infographic measure metrics ring statistics tracking",
    data_saver_on: "+ add analytics chart diagram infographic measure metrics new plus ring statistics symbol tracking",
    data_thresholding: "hidden privacy thresold",
    data_usage: "analytics chart circle diagram infographic measure metrics statistics tracking",
    date_range: "agenda calendar event month remember reminder schedule time today week",
    deblur: "adjust editing enhance face image lines photography sharpen",
    deck: "chairs furniture garden home house outdoors outside patio social terrace umbrella yard",
    dehaze: "adjust editing enhance image lines photography remove",
    delete: "bin garbage junk recycle remove trashcan",
    delete_forever: "bin cancel exit garbage junk recycle remove trashcan",
    delete_outline: "bin can garbage remove trash",
    delete_sweep: "bin garbage junk recycle remove trashcan",
    density_large: "horizontal lines rules",
    density_medium: "horizontal lines rules",
    density_small: "horizontal lines rules",
    departure_board: "automobile bus cars clock maps public schedule time transportation travel vehicle",
    description: "article bill data document drive file folders invoice item notes page paper sheet slide text writing",
    desktop_access_disabled: "Android apple chrome device display enabled hardware iOS mac monitor offline pc screen slash web window",
    desktop_mac: "Android apple chrome device display hardware iOS monitor pc screen web window",
    desktop_windows: "Android chrome device display hardware iOS mac monitor pc screen television tv web",
    details: "editing enhance image photography sharpen triangle",
    developer_board: "computer development devkit hardware microchip processor",
    developer_board_off: "computer development disabled enabled hardware microchip on processor slash",
    developer_mode: "Android bracket cell code development device engineer hardware iOS mobile phone tablet",
    device_hub: "Android circle computer desktop hardware iOS laptop mobile monitor phone square tablet triangle watch wearable web",
    devices: "Android computer desktop hardware iOS laptop mobile monitor phone tablet watch wearable web",
    devices_other: "Android cell chrome desktop gadget hardware iOS ipad mac mobile monitor phone smartwatch tablet vr wearables window",
    device_thermostat: "celsius fahrenheit temperature thermometer",
    device_unknown: "? Android assistance cell hardware help iOS information mark mobile phone punctuation question support symbol tablet",
    dialer_sip: "alphabet call cell character contact device font hardware initiation internet letter mobile over protocol routing session symbol telephone text type voice",
    dialpad: "buttons call contact device dots mobile numbers phone",
    diamond: "fashion gems jewelry logo retail valuables",
    difference: "compare content copy cut document duplicate file multiple past",
    dining: "cafeteria cutlery diner eating fork room spoon",
    dinner_dining: "breakfast food fork lunch meal restaurant spaghetti utensils",
    directions: "arrow maps naviate right route sign traffic",
    directions_bike: "bicycle human maps person public route transportation",
    directions_boat: "automobile cars ferry maps public transportation vehicle",
    directions_boat_filled: "automobile cars ferry maps public transportation vehicle",
    directions_bus: "automobile cars maps public transportation vehicle",
    directions_bus_filled: "automobile cars maps public transportation vehicle",
    directions_car: "automobile cars maps public transportation vehicle",
    directions_car_filled: "automobile cars maps public transportation vehicle",
    directions_off: "arrow disabled enabled maps right route sign slash traffic",
    directions_railway: "automobile cars maps public train transportation vehicle",
    directions_railway_filled: "automobile cars maps public train transportation vehicle",
    directions_run: "body health human jogging maps people person route running walk",
    directions_subway: "automobile cars maps public rail train transportation vehicle",
    directions_subway_filled: "automobile cars maps public rail train transportation vehicle",
    directions_transit: "automobile cars maps metro public rail subway train transportation vehicle",
    directions_transit_filled: "automobile cars maps public rail subway train transportation vehicle",
    directions_walk: "body human jogging maps people person route run",
    dirty_lens: "camera photography picture splat",
    disabled_by_default: "box cancel close exit no quit remove square stop",
    disc_full: "! alert attention caution cd danger error exclamation important mark music notification storage symbol vinyl warning",
    display_settings: "Android application change chrome desktop details device gear hardware iOS information mac monitor options personal screen service web window",
    dns: "address bars domain information ip list lookup name network server system",
    dock: "Android cell charger charging connector device hardware iOS mobile phone power station tablet",
    document_scanner: "article data drive file folders notes page paper sheet slide text writing",
    do_disturb: "cancel close denied deny remove silence stop",
    do_disturb_alt: "cancel close denied deny remove silence stop",
    do_disturb_off: "cancel close denied deny disabled enabled on remove silence slash stop",
    do_disturb_on: "cancel close denied deny disabled enabled off remove silence slash stop",
    domain: "apartment architecture building business estate home place real residence residential shelter web www",
    domain_add: "+ apartment architecture building business estate home new place plus real residence residential shelter symbol web www",
    domain_disabled: "apartment architecture building business company enabled estate home internet maps office offline on place real residence residential slash website",
    domain_verification: "application approve check complete design desktop done interface internet layout mark ok screen select tick ui ux validate verified website window www yes",
    done: "approve checkmark complete finished ok select success tick validate verified yes",
    done_all: "approve checkmark complete finished layers multiple ok select stack success tick validate verified yes",
    done_outline: "all approve checkmark complete finished ok select success tick validate verified yes",
    do_not_disturb: "cancel close denied deny remove silence stop",
    do_not_disturb_alt: "cancel close denied deny remove silence stop",
    do_not_disturb_off: "cancel close denied deny disabled enabled on remove silence slash stop",
    do_not_disturb_on: "cancel close denied deny disabled enabled off remove silence slash stop",
    do_not_disturb_on_total_silence: "busy mute on quiet total",
    do_not_step: "boot disabled enabled feet foot off on shoe slash sneaker",
    do_not_touch: "disabled enabled fingers gesture hand off on slash",
    donut_large: "analytics chart circle complete data diagram infographic inprogress, measure metrics pie statistics tracking",
    donut_small: "analytics chart circle data diagram infographic inprogress measure metrics pie statistics tracking",
    door_back: "closed doorway entrance exit home house",
    doorbell: "alarm home house ringing",
    door_front: "closed doorway entrance exit home house",
    door_sliding: "automatic doorway double entrance exit glass home house two",
    double_arrow: "arrows chevron direction multiple navigation right",
    downhill_skiing: "athlete athletic body entertainment exercise hobby human people person ski snow social sports travel winter",
    download: "arrow downloads drive install upload",
    download_done: "arrows check downloads drive installed ok tick upload",
    download_for_offline: "arrow circle for install offline upload",
    downloading: "arrow circle downloads install pending progress upload",
    drafts: "document email envelope file letter message read",
    drag_handle: "application components design interface layout lines menu move screen ui ux website window",
    drag_indicator: "application circles components design dots drop interface layout mobile monitor move phone screen shape shift tablet ui ux website window",
    drive_eta: "automobile cars destination direction estimate maps public transportation travel trip vehicle",
    drive_file_move: "arrows data direction document folders right sheet side slide storage",
    drive_file_rename_outline: "compose create draft editing input pencil write writing",
    drive_folder_upload: "arrow data document file sheet slide storage",
    dry: "air bathroom dryer fingers gesture hand wc",
    dry_cleaning: "hanger hotel laundry places service towel",
    duo: "call chat conference device video",
    dvr: "Android audio chrome computer desktop device display electronic hardware iOS laptop list mac monitor recorder screen tv video web window",
    dynamic_feed: "layer live multiple post refresh update",
    dynamic_form: "code electric fast lightning lists questionnaire thunderbolt",
    earbuds: "accessory audio earphone headphone listen music sound",
    earbuds_battery: "accessory audio charging earphone headphone listen music sound",
    east: "arrow directional maps navigation right",
    edgesensor_high: "Android cell device hardware iOS mobile move phone sensitivity tablet vibrate",
    edgesensor_low: "Android cell device hardware iOS mobile move phone sensitivity tablet vibrate",
    edit: "compose create editing input new pencil write writing",
    edit_attributes: "approve attribution check complete done mark ok select tick validate verified yes",
    edit_location: "destination direction gps maps pencil pin place stop write",
    edit_location_alt: "pencil pin",
    edit_notifications: "active alarm alert bell chime compose create draft editing input new notify pencil reminder ring sound write writing",
    edit_off: "compose create disabled draft editing enabled input new offline on pencil slash write writing",
    edit_road: "destination direction highway maps pencil street traffic",
    egg: "breakfast brunch food",
    egg_alt: "breakfast brunch food",
    eject: "arrow disc drive dvd player remove triangle up usb",
    elderly: "body cane human old people person senior",
    elderly_woman: "body cane female gender girl human lady old people person senior social symbol women",
    electrical_services: "charge cord plug power wire",
    electric_bike: "automobile cars electricity maps scooter transportation travel vehicle vespa",
    electric_bolt: "energy fast lightning nest thunderbolt",
    electric_car: "automobile cars electricity maps transportation travel vehicle",
    electric_meter: "energy fast lightning measure nest thunderbolt usage voltage volts",
    electric_moped: "automobile bike cars maps scooter transportation travel vehicle vespa",
    electric_rickshaw: "automobile cars india maps transportation truck vehicle",
    electric_scooter: "automobile bike cars maps transportation vehicle vespa",
    elevator: "body down human people person up",
    email: "envelope letter message note post receive send write",
    e_mobiledata: "alphabet font letter text type",
    emoji_emotions: "emoticon expressions face feelings glad happiness happy like mood person pleased smiley smiling social survey",
    emoji_events: "achievement award chalice champion cup first prize reward sport trophy winner",
    emoji_food_beverage: "coffee cup dring drink mug plate set tea",
    emoji_nature: "animal bee daisy flower honey insect ladybug petals spring summer",
    emoji_objects: "creative idea lamp lightbulb solution thinking",
    emoji_people: "arm body greeting human person social wave waving",
    emoji_symbols: "ampersand character hieroglyph music note percent sign",
    emoji_transportation: "architecture automobile building cars commute company direction estate maps office place public real residence residential shelter travel vehicle",
    energy_savings_leaf: "eco leaves nest usage",
    engineering: "body cogs cogwheel construction fixing gears hat helmet human maintenance people person setting worker",
    enhanced_encryption: "+ add locked new password plus privacy private protection safety secure security symbol",
    equalizer: "adjustment analytics chart data graph measure metrics music noise sound static statistics tracking volume",
    error: "! alert announcement attention caution circle danger exclamation feedback important mark notification problem symbol warning",
    error_outline: "! alert announcement attention caution circle danger exclamation feedback important mark notification problem symbol warning",
    escalator: "down staircase up",
    escalator_warning: "body child human kid parent people person",
    euro: "bill card cash coin commerce cost credit currency dollars euros finance money online payment price profit shopping symbol",
    euro_symbol: "bill card cash coin commerce cost credit currency dollars finance money online payment price profit",
    event: "agenda calendar date item mark month range remember reminder today week",
    event_available: "agenda approve calendar check complete done item mark ok schedule select tick time validate verified yes",
    event_busy: "agenda calendar cancel close date exit item no remove schedule stop time unavailable",
    event_note: "agenda calendar date item schedule text time writing",
    event_repeat: "around calendar date day inprogress loading month refresh renew rotate schedule turn",
    event_seat: "assigned bench chair furniture reservation row section sit",
    ev_station: "automobile cars charge charging electricity filling fuel gasoline maps places power station transportation vehicle",
    exit_to_app: "application arrow back components design export interface layout leave login logout mobile monitor move output phone pointing quit register right screen signin signout signup tablet ux website window",
    expand: "arrows compress enlarge grow move push together",
    expand_circle_down: "arrows chevron collapse direction expandable list more",
    expand_less: "arrows chevron collapse direction expandable list up",
    expand_more: "arrows chevron collapse direction down expandable list",
    explicit: "adult alphabet character content font language letter media movies music parent rating supervision symbol text type",
    explore: "compass destination direction east location maps needle north south travel west",
    explore_off: "compass destination direction disabled east enabled location maps needle north slash south travel west",
    exposure: "add brightness contrast editing effect image minus photography picture plus settings subtract",
    extension: "add-ons app extended game item jigsaw piece plugin puzzle shape",
    extension_off: "disabled enabled extended jigsaw piece puzzle shape slash",
    face: "account avatar emoji eyes human login logout people person profile recognition security social thumbnail unlock user",
    face_retouching_natural: "editing effect emoji emotion faces image photography settings star tag",
    face_retouching_off: "disabled editing effect emoji emotion enabled faces image natural photography settings slash tag",
    fact_check: "approve complete done list mark ok select tick validate verified yes",
    factory: "industry manufacturing warehouse",
    family_restroom: "bathroom children father kids mother parents wc",
    fastfood: "drink hamburger maps meal places",
    fast_forward: "control ff media music play speed time tv video",
    fast_rewind: "back control media music play speed time tv video",
    favorite: "appreciate health heart like love remember save shape success",
    favorite_border: "health heart like love outline remember save shape success",
    fax: "machine office phone send",
    featured_play_list: "audio collection highlighted item music playlist recommended",
    featured_video: "advertisement advertisment highlighted item play recommended watch,advertised",
    feed: "article headline information newspaper public social timeline",
    feedback: "! alert announcement attention bubble caution chat comment communicate danger error exclamation important mark message notification speech symbol warning",
    female: "gender girl lady social symbol woman women",
    fence: "backyard barrier boundaries boundary home house protection",
    festival: "circus event local maps places tent tour travel",
    fiber_dvr: "alphabet character digital electronics font letter network recorder symbol text tv type video",
    fiber_manual_record: "circle dot play watch",
    fiber_new: "alphabet character font letter network symbol text type",
    fiber_pin: "alphabet character font letter network symbol text type",
    fiber_smart_record: "circle dot play watch",
    file_copy: "bill clone content cut document duplicate invoice item multiple page past",
    file_download: "arrows downloads drive export install upload",
    file_download_done: "arrows check downloads drive installed tick upload",
    file_download_off: "arrow disabled drive enabled export install on save slash upload",
    file_open: "arrow document drive left page paper",
    file_present: "clip data document drive folders note paper reminder sheet slide storage writing",
    file_upload: "arrows download drive export",
    filter: "editing effect image landscape mountains photography picture settings",
    filter_1: "digit editing effect images multiple number photography pictures settings stack symbol",
    filter_2: "digit editing effect images multiple number photography pictures settings stack symbol",
    filter_3: "digit editing effect images multiple number photography pictures settings stack symbol",
    filter_4: "digit editing effect images multiple number photography pictures settings stack symbol",
    filter_5: "digit editing effect images multiple number photography pictures settings stack symbol",
    filter_6: "digit editing effect images multiple number photography pictures settings stack symbol",
    filter_7: "digit editing effect images multiple number photography pictures settings stack symbol",
    filter_8: "digit editing effect images multiple number photography pictures settings stack symbol",
    filter_9: "digit editing effect images multiple number photography pictures settings stack symbol",
    filter_9_plus: "+ digit editing effect images multiple number photography pictures settings stack symbol",
    filter_alt: "edit funnel options refine sift",
    filter_alt_off: "[offline] disabled edit funnel options refine sift slash",
    filter_b_and_w: "black contrast editing effect grayscale images photography pictures settings white",
    filter_center_focus: "camera dot edit image photography picture",
    filter_drama: "camera cloud editing effect image photography picture sky",
    filter_frames: "boarders border camera center editing effect filters focus image options photography picture",
    filter_hdr: "camera editing effect image mountains photography picture",
    filter_list: "lines organize sort",
    filter_list_off: "[offline] alt disabled edit options refine sift slash",
    filter_none: "multiple stack",
    filter_tilt_shift: "blur center editing effect focus images photography pictures",
    filter_vintage: "editing effect flower images photography pictures",
    find_in_page: "data document drive file folders glass look magnifying paper search see sheet slide writing",
    find_replace: "around arrows glass inprogress loading look magnifying refresh renew rotate search see",
    fingerprint: "biometrics identification identity reader thumbprint touchid verification",
    fire_extinguisher: "emergency water",
    fireplace: "chimney flame home house living pit room warm winter",
    first_page: "arrow back chevron left rewind",
    fitness_center: "athlete dumbbell exercise gym health hobby places sport weights workout",
    fit_screen: "enlarge format layout reduce scale size",
    flag: "country goal mark nation report start",
    flag_circle: "country goal mark nation report round start",
    flaky: "approve check close complete contrast done exit mark no ok options select stop tick verified yes",
    flare: "bright editing effect images lensflare light photography pictures shine sparkle star sun",
    flash_auto: "camera electric fast lightning thunderbolt",
    flashlight_off: "disabled enabled on slash",
    flashlight_on: "disabled enabled off slash",
    flash_off: "camera disabled electric enabled fast lightning on slash thunderbolt",
    flash_on: "camera disabled electric enabled fast lightning off slash thunderbolt",
    flatware: "cafeteria cutlery diner dining eating fork room spoon",
    flight: "airplane airport flying transportation travel trip",
    flight_class: "airplane business first seat transportation travel trip window",
    flight_land: "airplane airport arrival arriving flying landing transportation travel",
    flight_takeoff: "airplane airport departed departing flying landing transportation travel",
    flip: "editing image orientation scanning",
    flip_camera_android: "center editing front image mobile orientation rear reverse rotate turn",
    flip_camera_ios: "android editing front image mobile orientation rear reverse rotate turn",
    flip_to_back: "arrangement format front layout move order sort",
    flip_to_front: "arrangement back format layout move order sort",
    flutter_dash: "bird mascot",
    fmd_bad: "! alert attention caution danger destination direction error exclamation important location maps mark notification pin place symbol warning",
    fmd_good: "destination direction location maps pin place stop",
    folder: "data directory document drive file folders sheet slide storage",
    folder_delete: "bin can data document drive file folders garbage remove sheet slide storage trash",
    folder_off: "[online] data disabled document drive enabled file folders sheet slash slide storage",
    folder_open: "data directory document drive file folders sheet slide storage",
    folder_shared: "account collaboration data directory document drive face human people person profile sheet slide storage team user",
    folder_special: "bookmark data directory document drive favorite file highlight important marked saved shape sheet slide star storage",
    folder_zip: "compress data document drive file folders open sheet slide storage",
    follow_the_signs: "arrow body directional human people person right social",
    font_download: "A alphabet character letter square symbol text type",
    font_download_off: "alphabet character disabled enabled letter slash square symbol text type",
    food_bank: "architecture building charity eat estate fork house knife meal place real residence residential shelter utensils",
    forest: "jungle nature plantation plants trees woodland",
    fork_left: "arrows directions maps navigation path route sign traffic",
    fork_right: "arrows directions maps navigation path route sign traffic",
    format_align_center: "alignment doc editing editor lines spreadsheet text type writing",
    format_align_justify: "alignment density doc editing editor extra lines small spreadsheet text type writing",
    format_align_left: "alignment doc editing editor lines spreadsheet text type writing",
    format_align_right: "alignment doc editing editor lines spreadsheet text type writing",
    format_bold: "B alphabet character doc editing editor font letter spreadsheet styles symbol text type writing",
    format_clear: "T alphabet character disabled doc editing editor enabled font letter off slash spreadsheet style symbol text type writing",
    format_color_fill: "bucket doc editing editor paint spreadsheet style text type writing",
    format_color_reset: "clear disabled doc droplet editing editor enabled fill liquid off on paint slash spreadsheet style text type water writing",
    format_color_text: "doc editing editor fill paint spreadsheet style type writing",
    format_indent_decrease: "alignment doc editing editor indentation paragraph spreadsheet text type writing",
    format_indent_increase: "alignment doc editing editor indentation paragraph spreadsheet text type writing",
    format_italic: "alphabet character doc editing editor font letter spreadsheet style symbol text type writing",
    format_line_spacing: "alignment doc editing editor spreadsheet text type writing",
    format_list_bulleted: "alignment doc editing editor notes spreadsheet task text todo type writing",
    format_list_numbered: "alignment digit doc editing editor notes spreadsheet symbol task text todo type writing",
    format_list_numbered_rtl: "alignment digit doc editing editor notes spreadsheet symbol task text todo type writing",
    format_overline: "alphabet character doc editing editor font letter spreadsheet style symbol text type under writing",
    format_paint: "brush color doc editing editor fill paintroller spreadsheet style text type writing",
    format_quote: "doc editing editor quotation spreadsheet text type writing",
    format_shapes: "alphabet character color doc editing editor fill font letter paint spreadsheet style symbol text type writing",
    format_size: "alphabet character color doc editing editor fill font letter paint spreadsheet style symbol text type writing",
    format_strikethrough: "alphabet character doc editing editor font letter spreadsheet style symbol text type writing",
    format_textdirection_l_to_r: "alignment doc editing editor ltr paragraph spreadsheet type writing",
    format_textdirection_r_to_l: "alignment doc editing editor paragraph rtl spreadsheet type writing",
    format_underlined: "alphabet character doc editing editor font letter spreadsheet style symbol text type writing",
    forum: "bubble chat comment communicate community conversation feedback hub messages speech talk",
    forward: "arrow mail message playback right sent",
    forward_10: "arrow circle controls digit fast music number play rotate seconds speed symbol time video",
    forward_30: "arrow circle controls digit fast music number rotate seconds speed symbol time video",
    forward_5: "10 arrow circle controls digit fast music number rotate seconds speed symbol time video",
    forward_to_inbox: "arrow email envelop letter message send",
    foundation: "architecture base basis building construction estate home house real residential",
    free_breakfast: "beverage cafe coffee cup drink mug tea",
    fullscreen: "adjust application components interface size ui ux view website",
    fullscreen_exit: "adjust application components interface size ui ux view website",
    functions: "average calculate count doc editing editor math sigma spreadsheet style sum text type writing",
    gamepad: "buttons console controller device gaming playstation video",
    games: "adjust arrows controller direction dpad gaming left move nintendo playstation right xbox",
    garage: "automobile automotive cars direction maps transportation travel vehicle",
    gas_meter: "droplet energy measure nest usage water",
    gavel: "agreement contract court document government hammer judge law mallet official police rules terms",
    gesture: "drawing finger gestures hand line motion",
    get_app: "arrows downloads export install play pointing retrieve upload",
    gif: "alphabet animated animation bitmap character font format graphics interchange letter symbol text type",
    gif_box: "alphabet animated animation bitmap character font format graphics interchange letter symbol text type",
    girl: "body female gender human lady people person social symbol woman women",
    gite: "architecture estate home hostel house maps place real residence residential stay traveling",
    g_mobiledata: "alphabet character font letter network service symbol text type",
    golf_course: "athlete athletic ball club entertainment flag golfer golfing hobby hole places putt sports",
    gpp_bad: "cancel certified close error exit no privacy private protection remove security shield sim stop verified",
    gpp_good: "certified check ok pass security shield sim tick",
    gpp_maybe: "! alert attention caution certified danger error exclamation important mark notification privacy private protection security shield sim symbol verified warning",
    gps_fixed: "destination direction location maps pin place pointer stop tracking",
    gps_not_fixed: "destination direction disabled enabled fixed location maps not off online place pointer slash tracking",
    gps_off: "destination direction disabled enabled fixed location maps not offline place pointer slash tracking",
    grade: "achievement important likes marked rated rating reward saved shape special star",
    gradient: "color editing effect filter images photography pictures",
    grading: "approve check complete document done feedback grade mark ok reviewed select tick validate verified writing yes",
    grain: "dots editing effect filter images photography pictures",
    graphic_eq: "audio equalizer music recording sound voice",
    grass: "backyard fodder ground home lawn plant turf",
    grid_goldenratio: "layout lines space",
    grid_off: "collage disabled enabled image layout on slash view",
    grid_on: "collage disabled enabled image layout off sheet slash view",
    grid_view: "application blocks components dashboard design interface layout screen square tiles ui ux website window",
    group: "accounts committee face family friends humans network people persons profiles social team users",
    group_add: "accounts committee face family friends humans increase more network people persons plus profiles social team users",
    group_remove: "accounts committee face family friends humans network people persons profiles social team users",
    groups: "body club collaboration crowd gathering human meeting people person social teams",
    group_work: "alliance circle collaboration film partnership reel teamwork together",
    g_translate: "emblem google language logo mark speaking speech translator words",
    hail: "body human people person pick public stop taxi transportation",
    handyman: "build construction fix hammer repair screwdriver tools",
    hardware: "break construction hammer nail repair tool",
    hd: "alphabet character definition display font high letter movies quality resolution screen symbol text tv type video",
    hdr_auto: "A alphabet camera character circle dynamic font high letter photo range symbol text type",
    hdr_auto_select: "+ A alphabet camera character circle dynamic font high letter photo range symbol text type",
    hdr_enhanced_select: "add alphabet character dynamic font high letter plus range symbol text type",
    hdr_off: "alphabet character disabled dynamic enabled enhance font high letter range select slash symbol text type",
    hdr_off_select: "alphabet camera character circle disabled dynamic enabled font high letter photo range slash symbol text type",
    hdr_on: "add alphabet character dynamic enhance font high letter plus range select symbol text type",
    hdr_on_select: "+ alphabet camera character circle dynamic font high letter photo range symbol text type",
    hdr_plus: "+ add alphabet character circle dynamic enhance font high letter range select symbol text type",
    hdr_strong: "circles dots dynamic enhance high range",
    hdr_weak: "circles dots dynamic enhance high range",
    headphones: "accessory audio device earphone headset listen music sound",
    headphones_battery: "accessory audio charging device earphone headset listen music sound",
    headset: "accessory audio device earbuds earmuffs earphone headphones listen music sound",
    headset_mic: "accessory audio chat device earphone headphones listen music sound talk",
    headset_off: "accessory audio chat device disabled earphone enabled headphones listen mic music slash sound talk",
    healing: "bandage bandaid editing emergency fix health hospital image medicine",
    health_and_safety: "+ add certified plus privacy private protection security shield symbol verified",
    hearing: "accessibility accessible aid handicap help impaired listen sound volume",
    hearing_disabled: "accessibility accessible aid enabled handicap help impaired listen off on slash sound volume",
    heart_broken: "break core crush health nucleus split",
    heat_pump: "air conditioner cool energy furnance nest usage",
    height: "arrows color doc down editing editor fill format paint resize spreadsheet stretch style text type up writing",
    help: "? alert announcement assistance circle information mark punctuation question shape support symbol",
    help_center: "? assistance information mark punctuation question support symbol",
    help_outline: "? alert announcement assistance circle information mark punctuation question shape support symbol",
    hevc: "alphabet character coding efficiency font high letter symbol text type video",
    hexagon: "shape sides six",
    hide_image: "disabled enabled landscape mountains off on photography picture slash",
    hide_source: "circle disabled enabled offline on shape slash",
    highlight: "color doc editing editor emphasize fill flashlight format marker paint spreadsheet style text type writing",
    highlight_off: "cancel circle clear click close delete disable exit focus no quit remove stop target times",
    high_quality: "alphabet character definition display font hq letter movies resolution screen symbol text tv type",
    hiking: "backpacking bag climbing duffle mountain social sports stick trail travel walking",
    history: "arrow backwards clock date refresh renew reverse revert rotate schedule time turn undo",
    history_edu: "document education feather letter paper pen quill school tools write writing",
    history_toggle_off: "clock date schedule time",
    hls: "alphabet character developer engineering font letter platform symbol text type",
    hls_off: "[offline] alphabet character developer disabled enabled engineering font letter platform slash symbol text type",
    h_mobiledata: "alphabet character font letter network service symbol text type",
    holiday_village: "architecture beach camping cottage estate home house lake lodge maps place real residence residential stay traveling vacation",
    home: "address application--house architecture building components design estate homepage interface layout place real residence residential screen shelter structure unit ux website window",
    home_max: "device gadget hardware internet iot nest smart things",
    home_mini: "Internet device gadget hardware iot nest smart things",
    home_repair_service: "equipment fix kit mechanic repairing toolbox tools workshop",
    home_work: "architecture building estate house office place real residence residential shelter",
    horizontal_rule: "gmail line novitas",
    horizontal_split: "bars format layout lines stacked",
    hotel: "bed body human people person sleep stay travel trip",
    hot_tub: "bathing bathroom bathtub hotel human jacuzzi person shower spa steam travel water",
    hourglass_bottom: "countdown half loading minutes time waiting",
    hourglass_disabled: "clock countdown empty enabled loading minutes off on slash time waiting",
    hourglass_empty: "countdown loading minutes start time waiting",
    hourglass_full: "countdown loading minutes time waiting",
    hourglass_top: "countdown half loading minutes time waiting",
    house: "architecture building estate family homepage places real residence residential shelter",
    houseboat: "architecture beach estate floating home maps place real residence residential sea stay traveling vacation",
    house_siding: "architecture building construction estate exterior facade home real residential",
    how_to_reg: "approve ballot check complete done election mark ok poll register registration select tick to validate verified vote yes",
    how_to_vote: "ballot election poll",
    h_plus_mobiledata: "+ alphabet character font letter network service symbol text type",
    html: "alphabet brackets character code css developer engineering font letter platform symbol text type",
    http: "alphabet character font internet letter network symbol text transfer type url website",
    https: "connection encrypt internet key locked network password privacy private protection safety secure security ssl web",
    hub: "center connection core focal network nucleus point topology",
    hvac: "air conditioning heating ventilation",
    icecream: "dessert food snack",
    ice_skating: "athlete athletic entertainment exercise hobby shoe skates social sports travel",
    image: "disabled enabled frame hide landscape mountains off on photography picture slash",
    image_aspect_ratio: "photography picture rectangle square",
    image_not_supported: "disabled enabled landscape mountains off on photography picture slash",
    image_search: "find glass landscape look magnifying mountains photography picture see",
    imagesearch_roller: "art paint",
    important_devices: "Android cell computer desktop hardware iOS mobile monitor phone star tablet web",
    import_contacts: "address book friends information magazine open",
    import_export: "arrows direction down explort up",
    inbox: "archive email incoming message",
    indeterminate_check_box: "application button components control design form interface minus screen selected selection square toggle ui undetermined ux website",
    info: "about alert announcement announcment assistance bubble circle details help information service support",
    input: "arrow box download login move right",
    insert_chart: "analytics barchart bars data diagram infographic measure metrics statistics tracking",
    insert_chart_outlined: "analytics bars data diagram infographic measure metrics statistics tracking",
    insert_comment: "add bubble chat feedback message",
    insert_drive_file: "bill document format invoice item sheet slide",
    insert_emoticon: "account emoji face happy human like people person profile sentiment smiley user",
    insert_invitation: "agenda calendar date event mark month range remember reminder today week",
    insert_link: "add anchor attach clip file mail media",
    insert_page_break: "document file paper",
    insert_photo: "image landscape mountains photography picture wallpaper",
    insights: "analytics bars chart data diagram infographic measure metrics stars statistics tracking",
    install_desktop: "Android chrome device display fix hardware iOS mac monitor place pwa screen web window",
    install_mobile: "Android cell device hardware iOS phone pwa tablet",
    integration_instructions: "brackets clipboard code css developer document engineering html platform",
    interests: "circle heart shapes social square triangle",
    interpreter_mode: "language microphone person speaking symbol",
    inventory: "archive box buy check clipboard document e-commerce file list organize packages product purchase shop stock store supply",
    invert_colors: "droplet editing hue inverted liquid palette tone water",
    invert_colors_off: "disabled droplet enabled hue inverted liquid offline opacity palette slash tone water",
    ios_share: "arrows button direction export internet link send sharing social up website",
    iron: "appliance clothes electric ironing machine object",
    iso: "add editing effect image minus photography picture plus sensor shutter speed subtract",
    javascript: "alphabet brackets character code css developer engineering font html letter platform symbol text type",
    join_full: "circle combine command left outter right sql",
    join_inner: "circle command matching sql values",
    join_left: "circle command matching sql values",
    join_right: "circle command matching sql values",
    kayaking: "athlete athletic body canoe entertainment exercise hobby human lake paddle paddling people person rafting river row social sports summer travel water",
    key: "blackout password restricted secret unlock",
    keyboard: "computer device hardware input keypad letter office text type",
    keyboard_alt: "computer device hardware input keypad letter office text type",
    keyboard_arrow_down: "arrows chevron open",
    keyboard_arrow_left: "arrows chevron",
    keyboard_arrow_right: "arrows chevron open start",
    keyboard_arrow_up: "arrows chevron submit",
    keyboard_backspace: "arrow left",
    keyboard_capslock: "arrow up",
    keyboard_command_key: "button command control key",
    keyboard_control_key: "control key",
    keyboard_double_arrow_down: "arrows direction multiple navigation",
    keyboard_double_arrow_left: "arrows direction multiple navigation",
    keyboard_double_arrow_right: "arrows direction multiple navigation",
    keyboard_double_arrow_up: "arrows direction multiple navigation",
    keyboard_hide: "arrow computer device down hardware input keypad text",
    keyboard_option_key: "alt key modifier",
    keyboard_return: "arrow back left",
    keyboard_tab: "arrow next right",
    keyboard_voice: "microphone noise recorder speaker",
    key_off: "[offline] disabled enabled on password slash unlock",
    king_bed: "bedroom double furniture home hotel house night pillows queen rest sleep",
    kitchen: "appliance cabinet cold food freezer fridge home house ice places refrigerator storage",
    kitesurfing: "athlete athletic beach body entertainment exercise hobby human people person social sports travel water",
    label: "badge favorite indent item library mail remember save stamp sticker tag",
    label_important: "badge favorite important. indent item library mail remember save stamp sticker tag wing",
    label_off: "disabled enabled favorite indent library mail on remember save slash stamp sticker tag wing",
    lan: "computer connection data internet network service",
    landscape: "image mountains nature photography picture",
    language: "country earth globe i18n internet l10n planet website world www",
    laptop: "Android chrome computer connect desktop device display hardware iOS link mac monitor smart tv web windows",
    laptop_chromebook: "Android chromebook device display hardware iOS mac monitor screen web window",
    laptop_mac: "Android apple chrome device display hardware iOS monitor screen web window",
    laptop_windows: "Android chrome device display hardware iOS mac monitor screen web",
    last_page: "application arrow chevron components end forward interface right screen ui ux website",
    launch: "application arrow box components core interface internal new open screen ui ux website window",
    layers: "arrange disabled enabled interaction maps off overlay pages slash stack",
    layers_clear: "arrange delete disabled enabled interaction maps off overlay pages slash",
    leaderboard: "analytics bars chart data diagram infographic measure metrics statistics tracking",
    leak_add: "connection data link network service signals synce wireless",
    leak_remove: "connection data disabled enabled link network offline service signals slash synce wireless",
    legend_toggle: "analytics chart data diagram infographic measure metrics monitoring stackdriver statistics tracking",
    lens: "circle full geometry moon",
    lens_blur: "camera dim dot effect foggy fuzzy image photo soften",
    library_add: "+ collection layers multiple music new plus save stacked symbol video",
    library_add_check: "approve collection complete done layers mark multiple music ok select stacked tick validate verified video yes",
    library_books: "add album audio collection reading",
    library_music: "add album audio collection song sounds",
    light: "bulb ceiling hanging inside interior lamp lighting pendent room",
    lightbulb: "alert announcement idea information learning mode",
    lightbulb_circle: "alert announcement idea information",
    light_mode: "brightness day device lighting morning mornng sky sunny",
    linear_scale: "application components design interface layout measure menu screen slider ui ux website window",
    line_axis: "dash horizontal stroke vertical",
    line_style: "dash dotted editor rule spacing",
    line_weight: "editor height size spacing style thickness",
    link: "anchor chain clip connection external hyperlink linked links multimedia unlisted url",
    linked_camera: "connection lens network photography picture signals sync wireless",
    link_off: "anchor attached chain clip connection disabled enabled linked links multimedia slash unlink url",
    liquor: "alcohol bar bottle club cocktail drink food party store wine",
    list: "editor file format index menu options playlist task todo",
    list_alt: "box contained editor format lines reorder sheet stacked task title todo",
    live_help: "? alert announcement assistance bubble chat comment communicate faq information mark message punctuation question speech support symbol",
    live_tv: "Android antennas chrome desktop device hardware iOS mac monitor movie play stream television web window",
    living: "chair comfort couch decoration furniture home house lounging loveseat room seating sofa",
    local_activity: "event star things ticket",
    local_airport: "airplane flight flying transportation travel trip",
    local_atm: "bill card cart cash coin commerce credit currency dollars financial money online payment price profit shopping symbol",
    local_bar: "alcohol bottle club cocktail drink food liquor martini wine",
    local_cafe: "bottle coffee cup drink food mug restaurant tea",
    local_car_wash: "automobile cars maps transportation travel vehicle",
    local_convenience_store: "-- 24 bill building business card cash coin commerce company credit currency dollars maps market money new online payment plus shopping storefront symbol",
    local_dining: "cutlery eat food fork knife meal restaurant spoon",
    local_drink: "cup droplet glass liquid park water",
    local_fire_department: "911 firefighter flame hot",
    local_florist: "flower shop",
    local_gas_station: "auto car filling fuel gasoline oil station vehicle",
    local_grocery_store: "market shop",
    local_hospital: "911 aid cross doctor emergency first health medical medicine plus",
    local_hotel: "bed body human people person sleep stay travel trip",
    local_laundry_service: "cleaning clothing dryer hotel washer",
    local_library: "book community learning person read",
    local_mall: "bill building business buy card cart cash coin commerce credit currency dollars handbag money online payment shopping storefront",
    local_offer: "deal discount price shopping store tag",
    local_parking: "alphabet auto car character font garage letter symbol text type vehicle",
    local_pharmacy: "911 aid cross emergency first food hospital medicine places",
    local_phone: "booth call telecommunication",
    local_pizza: "drink fastfood meal",
    local_police: "911 badge law officer protection security shield",
    local_post_office: "delivery email envelop letter message package parcel postal send stamp",
    local_printshop: "draft fax ink machine office paper printer send",
    local_see: "camera lens photography picture",
    local_shipping: "automobile cars delivery letter mail maps office package parcel postal semi send shopping stamp transportation truck vehicle",
    local_taxi: "automobile cab call cars direction lyft maps public transportation uber vehicle yellow",
    location_city: "apartments architecture buildings business company estate home landscape place real residence residential shelter town urban",
    location_disabled: "destination direction enabled maps off pin place pointer slash stop tracking",
    location_off: "destination direction disabled enabled gps maps pin place room slash stop",
    location_on: "destination direction disabled enabled gps maps off pin place room slash stop",
    location_searching: "destination direction maps pin place pointer stop tracking",
    lock: "connection key locked logout padlock password privacy private protection safety secure security signout",
    lock_clock: "date locked password privacy private protection safety schedule secure security time",
    lock_open: "connection key login padlock password privacy private protection register safety secure security signin signup unlocked",
    lock_reset: "around inprogress loading locked password privacy private protection refresh renew rotate safety secure security turn",
    login: "access application arrow components design enter interface left screen ui ux website",
    logo_dev: "dev.to",
    logout: "application arrow components design exit interface leave login right screen ui ux website",
    looks: "circle half rainbow",
    looks_3: "digit numbers square symbol",
    looks_4: "digit numbers square symbol",
    looks_5: "digit numbers square symbol",
    looks_6: "digit numbers square symbol",
    looks_one: "1 digit numbers square symbol",
    looks_two: "2 digit numbers square symbol",
    loop: "around arrows direction inprogress loader loading music navigation refresh renew repeat rotate turn",
    loupe: "+ add details focus glass magnifying new plus symbol",
    low_priority: "arrange arrow backward bottom list move order task todo",
    loyalty: "badge card credit heart love membership miles points program sale subscription tag travel trip",
    lte_mobiledata: "alphabet character font internet letter network speed symbol text type wifi wireless",
    lte_plus_mobiledata: "+ alphabet character font internet letter network speed symbol text type wifi wireless",
    luggage: "airport baggage carry flight hotel on suitcase travel trip",
    lunch_dining: "breakfast dinner drink fastfood hamburger meal",
    lyrics: "audio bubble chat comment communicate feedback key message music note song sound speech track",
    mail: "email envelope inbox letter message send",
    mail_lock: "email envelop letter locked message password privacy private protection safety secure security send",
    mail_outline: "email envelope letter message note post receive send write",
    male: "boy gender man social symbol",
    man: "boy gender male social symbol",
    manage_accounts: "change details face gear options people person profile service-human settings user",
    manage_search: "glass history magnifying text",
    map: "destination direction location maps pin place route stop travel",
    maps_home_work: "building house office",
    maps_ugc: "+ add bubble comment communicate feedback message new plus speech symbol",
    margin: "design layout padding size square",
    mark_as_unread: "envelop letter mail postal receive send",
    mark_chat_read: "approve bubble check comment communicate complete done message ok select sent speech tick verified yes",
    mark_chat_unread: "bubble circle comment communicate message notification speech",
    mark_email_read: "approve check complete done envelop letter message note ok select send sent tick yes",
    mark_email_unread: "check circle envelop letter message note notification send",
    markunread: "email envelope letter message send",
    markunread_mailbox: "deliver envelop letter postal postbox receive send",
    masks: "air cover covid face hospital medical pollution protection respirator sick social",
    maximize: "application components design interface line screen shape ui ux website",
    media_bluetooth_off: "connection connectivity device disabled enabled music note offline paring signal slash symbol wireless",
    media_bluetooth_on: "connection connectivity device disabled enabled music note off online paring signal slash symbol wireless",
    mediation: "alternative arrows compromise direction dots negotiation party right structure",
    medical_services: "aid bag briefcase emergency first kit medicine",
    medication: "doctor drug emergency hospital medicine pharmacy pills prescription",
    meeting_room: "building doorway entrance home house interior logout office open places signout",
    memory: "card chip digital micro processor sd storage",
    menu: "application components hamburger interface lines playlist screen ui ux website",
    menu_book: "dining food meal page restaurant",
    menu_open: "application arrow chevron components hamburger interface left lines screen ui ux website",
    merge: "arrows directions maps navigation path route sign traffic",
    merge_type: "arrow combine direction format text",
    message: "bubble chat comment communicate feedback speech talk text",
    mic: "hearing microphone noise record search sound speech voice",
    mic_external_off: "audio disabled enabled microphone slash sound voice",
    mic_external_on: "audio disabled enabled microphone off slash sound voice",
    mic_none: "hearing microphone noise record sound voice",
    mic_off: "audio disabled enabled hearing microphone noise recording slash sound voice",
    microwave: "appliance cooking electric heat home house kitchen machine",
    military_tech: "army award badge honor medal merit order privilege prize rank reward ribbon soldier star status trophy winner",
    minimize: "application components design interface line screen shape ui ux website",
    missed_video_call: "arrow camera filming hardware image motion picture record videography",
    mms: "bubble chat comment communicate feedback image landscape message mountains multimedia photography picture speech",
    mobiledata_off: "arrow disabled down enabled internet network on slash speed up wifi wireless",
    mobile_friendly: "Android approve cell check complete device done hardware iOS mark ok phone select tablet tick validate verified yes",
    mobile_off: "Android cell device disabled enabled hardware iOS phone silence slash tablet",
    mobile_screen_share: "Android arrow cell device hardware iOS mirror monitor phone screencast streaming tablet tv wireless",
    mode: "compose create draft draw edit pencil write",
    mode_comment: "bubble chat comment communicate feedback message mode speech",
    mode_edit: "compose create draft draw pencil write",
    mode_edit_outline: "compose create draft draw pencil write",
    model_training: "arrow bulb idea inprogress light loading refresh renew restore reverse rotate",
    mode_night: "dark disturb moon sleep weather",
    mode_of_travel: "arrow destination direction location maps pin place stop transportation trip",
    mode_standby: "disturb power sleep target",
    monetization_on: "bill card cash circle coin commerce cost credit currency dollars finance money online payment price profit sale shopping symbol",
    money: "100 bill card cash coin commerce cost credit currency digit dollars finance number online payment price profit shopping symbol",
    money_off: "bill card cart cash coin commerce credit currency disabled dollars enabled finance money online payment price profit shopping slash symbol",
    money_off_csred: "bill card cart cash coin commerce credit currency disabled dollars enabled online payment shopping slash symbol",
    monitor: "Android chrome device display hardware iOS mac screen web window",
    monitor_weight: "body device diet health scale smart",
    monochrome_photos: "black camera image photography picture white",
    mood: "emoji emoticon emotions expressions face feelings glad happiness happy like person pleased smiley smiling social survey",
    mood_bad: "disappointment dislike emoji emoticon emotions expressions face feelings person rating smiley social survey unhappiness unhappy unpleased unsmile unsmiling",
    more: "3 archive badge bookmark dots etc favorite indent label remember save stamp sticker tab tag three",
    more_horiz: "3 application components dots etc horizontal interface ios pending screen status three ui ux website",
    more_time: "+ add clock date new plus schedule symbol",
    more_vert: "3 android application components dots etc interface screen three ui ux vertical website",
    motion_photos_auto: "A alphabet animation automatic character circle font gif letter live symbol text type video",
    motion_photos_off: "animation circle disabled enabled slash video",
    mouse: "click computer cursor device hardware wireless",
    move_down: "arrow direction jump navigation transfer",
    move_to_inbox: "archive arrow down email envelop incoming letter message move send to",
    move_up: "arrow direction jump navigation transfer",
    movie: "cinema film media screen show slate tv video watch",
    movie_creation: "clapperboard film movies slate video",
    movie_filter: "clapperboard creation film movies slate stars video",
    moving: "arrow direction navigation travel up",
    mp: "alphabet character font image letter megapixel photography pixels quality resolution symbol text type",
    multiline_chart: "analytics bars data diagram infographic line measure metrics multiple statistics tracking",
    multiple_stop: "arrows directions dots left maps navigation right",
    museum: "architecture attraction building estate event exhibition explore local palces places real see shop store tour",
    music_note: "audiotrack key sound",
    music_off: "audiotrack disabled enabled key note on slash sound",
    music_video: "band mv recording screen tv watch",
    my_location: "destination direction maps navigation pin place point stop",
    nat: "communication",
    nature: "forest outdoor outside park tree wilderness",
    nature_people: "activity body forest human outdoor outside park person tree wilderness",
    navigate_before: "arrows direction left",
    navigate_next: "arrows direction right",
    navigation: "arrow destination direction location maps pin place point stop",
    nearby_error: "! alert attention caution danger exclamation important mark notification symbol warning",
    nearby_off: "disabled enabled on slash",
    near_me: "arrow destination direction location maps navigation pin place point stop",
    near_me_disabled: "destination direction enabled location maps navigation off pin place point slash",
    nest_cam_wired_stand: "camera filming hardware image motion picture videography",
    network_cell: "cellular data internet mobile phone speed wifi wireless",
    network_check: "connection internet meter signal speed tick wifi wireless",
    network_locked: "alert available cellular connection data error internet mobile not privacy private protection restricted safety secure security service signal warning wifi wireless",
    network_wifi: "cellular data internet mobile phone speed wireless",
    new_releases: "! alert announcement attention burst caution danger error exclamation important mark notification star symbol warning",
    newspaper: "article data document drive file folders magazine media notes page sheet slide text writing",
    next_plan: "arrow circle right",
    next_week: "arrow baggage briefcase business suitcase",
    nfc: "communication data field mobile near wireless",
    nightlife: "alcohol bar bottle club cocktail dance drink food glass liquor music note wine",
    nightlight: "dark disturb mode moon sleep weather",
    nightlight_round: "dark half mode moon",
    night_shelter: "architecture bed building estate homeless house place real sleep",
    nights_stay: "cloud crescent dark mode moon phases silence silent sky time weather",
    no_accounts: "avatar disabled enabled face human offline people person profile slash thumbnail unavailable unidentifiable unknown user",
    no_backpack: "accessory bookbag knapsack travel",
    no_drinks: "alcohol beverage bottle cocktail food liquor wine",
    no_encryption: "disabled enabled lock off password safety security slash",
    no_encryption_gmailerrorred: "disabled enabled locked off slash",
    no_flash: "camera disabled enabled image lightning off on photography picture slash thunderbolt",
    no_food: "disabled drink enabled fastfood hamburger meal off on slash",
    no_luggage: "baggage carry disabled enabled off on slash suitcase travel",
    no_meals: "dining disabled eat enabled food fork knife off restaurant slash spoon utensils",
    no_meeting_room: "building disabled doorway enabled entrance home house interior office on open places slash",
    no_photography: "camera disabled enabled image off on picture slash",
    nordic_walking: "athlete athletic body entertainment exercise hiking hobby human people person social sports travel walker",
    north: "arrow directional maps navigation up",
    north_east: "arrow maps navigation noth right up",
    north_west: "arrow directional left maps navigation up",
    no_sim: "camera card device eject insert memory phone storage",
    no_stroller: "baby care carriage children disabled enabled infant kid newborn off on parents slash toddler young",
    not_accessible: "accessibility body handicap help human person wheelchair",
    note: "bookmark message paper",
    note_add: "+ -doc create data document drive file folders new page paper plus sheet slide symbol writing",
    note_alt: "clipboard document file memo page paper writing",
    notes: "comment document text write writing",
    notification_add: "+ active alarm alert bell chime notifications notify plus reminder ring sound symbol",
    notification_important: "! active alarm alert announcement attention bell caution chime danger error exclamation feedback mark notifications notify problem reminder ring sound symbol warning",
    notifications: "active alarm alert bell chime notify reminder ring sound",
    notifications_active: "alarm alert bell chime notify reminder ringing sound",
    notifications_none: "alarm alert bell notify reminder ring sound",
    notifications_off: "active alarm alert bell chime disabled enabled notify offline reminder ring slash sound",
    notifications_paused: "--- active alarm aleet alert bell chime ignore notify pause quiet reminder ring sleep snooze sound zzz",
    not_interested: "allowed banned cancel circle close disabled dislike exit interested not off prohibited quit remove stop",
    not_listed_location: "? assistance destination direction help information maps pin place punctuation questionmark stop support symbol",
    no_transfer: "automobile bus cars direction disabled enabled maps off public slash transportation vehicle",
    not_started: "circle media pause play video",
    offline_bolt: "circle electric fast flash lightning spark thunderbolt",
    offline_pin: "approve checkmark circle complete done ok select tick validate verified yes",
    offline_share: "Android arrow cell connect device direction hardware iOS link mobile multiple phone right tablet",
    oil_barrel: "droplet gasoline nest water",
    ondemand_video: "Android chrome desktop device hardware iOS mac monitor play television tv web window",
    on_device_training: "arrow bulb call cell contact hardware idea inprogress light loading mobile model refresh renew restore reverse rotate telephone",
    online_prediction: "bulb connection idea light network signal wireless",
    opacity: "color droplet hue inverted liquid palette tone water",
    open_in_browser: "arrow box new up website window",
    open_in_full: "action arrows expand grow move",
    open_in_new: "application arrow box components interface screen ui ux website window",
    open_in_new_off: "arrow box disabled enabled export on slash window",
    open_with: "arrows directional expand move",
    other_houses: "architecture cottage estate home maps place real residence residential stay traveling",
    outbound: "arrow circle directional right up",
    outbox: "mail send sent",
    outdoor_grill: "barbecue barbeque bbq charcoal cooking home house outside",
    outlet: "connecter electricity plug power",
    outlined_flag: "country goal mark nation report start",
    padding: "design layout margin size square",
    pages: "article gplus paper post star",
    pageview: "document find glass magnifying paper search",
    paid: "circle currency money payment transaction",
    palette: "art colors filters paint",
    panorama: "angle image mountains photography picture view wide",
    panorama_fish_eye: "angle circle image photography picture wide",
    panorama_horizontal: "angle image photography picture wide",
    panorama_photosphere: "angle horizontal image photography picture wide",
    panorama_vertical: "angle image photography picture wide",
    panorama_wide_angle: "image photography picture",
    pan_tool: "drag fingers gesture hands human move scan stop touch wait",
    paragliding: "athlete athletic body entertainment exercise fly hobby human parachute people person skydiving social sports travel",
    park: "attraction fresh local nature outside plant tree",
    party_mode: "camera lens photography picture",
    password: "key login pin security star unlock",
    pattern: "key login password pin security star unlock",
    pause: "controls media music pending player status video wait",
    pause_circle: "controls media music video",
    pause_circle_filled: "controls media music pending status video wait",
    pause_circle_outline: "controls media music pending status video wait",
    pause_presentation: "application desktop device pending screen share slides status wait website window www",
    payment: "bill cash charge coin commerce cost creditcard currency dollars finance financial information money online price shopping symbol",
    payments: "bill card cash coin commerce cost credit currency dollars finance layer money multiple online price shopping symbol",
    pedal_bike: "automobile bicycle cars direction human maps public route scooter transportation vehicle vespa",
    pending: "circle dots loading progress waiting",
    pending_actions: "clipboard clock date document remember schedule time",
    pentagon: "five shape sides",
    people: "accounts committee community face family friends group humans network persons profiles social team users",
    people_alt: "accounts committee face family friends group humans network persons profiles social team users",
    people_outline: "accounts committee face family friends group humans network persons profiles social team users",
    percent: "math number symbol",
    perm_camera_mic: "image microphone min photography picture speaker",
    perm_contact_calendar: "account agenda date face human information people person profile schedule time user",
    perm_data_setting: "cellular configure gear information network settings wifi wireless",
    perm_device_information: "Android alert announcement cell hardware iOS important mobile phone tablet",
    perm_identity: "account avatar face human information people person profile save, thumbnail user",
    perm_media: "collection data directories document file folders images landscape mountains photography picture save storage",
    perm_phone_msg: "bubble call cell chat comment communicate contact device message mobile recording save speech telephone voice",
    perm_scan_wifi: "alert announcement connection information internet network service signal wireless",
    person: "account avatar face human people profile user",
    person_add: "+ account avatar face friend human new people plus profile symbol user",
    person_add_alt: "+ account face human people plus profile user",
    person_add_disabled: "+ account enabled face human new offline people plus profile slash symbol user",
    personal_video: "Android cam chrome desktop device hardware iOS mac monitor television tv web window",
    person_off: "account avatar disabled enabled face human people profile slash user",
    person_outline: "account avatar face human people profile user",
    person_pin: "account avatar destination direction face gps human location maps people place profile stop user",
    person_pin_circle: "account destination direction face gps human location maps people place profile stop user",
    person_remove: "account avatar delete face human minus people profile unfriend user",
    person_search: "account avatar face find glass human look magnifying people profile user",
    pest_control: "bug exterminator insects",
    pest_control_rodent: "exterminator mice",
    pets: "animal cat claw dog hand paw",
    phishing: "fishing fraud hook scam",
    phone: "call cell chat contact device hardware mobile telephone text",
    phone_android: "cell device hardware iOS mobile tablet",
    phone_bluetooth_speaker: "call cell connection connectivity contact device hardware mobile signal symbol telephone wireless",
    phone_callback: "arrow cell contact device down hardware mobile telephone",
    phone_disabled: "call cell contact device enabled hardware mobile offline slash telephone",
    phone_enabled: "call cell contact device hardware mobile telephone",
    phone_forwarded: "arrow call cell contact device direction hardware mobile right telephone",
    phone_iphone: "Android apple cell device hardware iOS mobile tablet",
    phonelink: "Android chrome computer connect desktop device hardware iOS mac mobile sync tablet web windows",
    phonelink_erase: "Android cancel cell close connection device exit hardware iOS mobile no remove stop tablet",
    phonelink_lock: "Android cell connection device erase hardware iOS locked mobile password privacy private protection safety secure security tablet",
    phonelink_off: "Android chrome computer connect desktop device disabled enabled hardware iOS mac mobile slash sync tablet web windows",
    phonelink_ring: "Android cell connection data device hardware iOS mobile network service signal tablet wireless",
    phonelink_setup: "Android call chat device hardware iOS information mobile settings tablet text",
    phone_locked: "call cell contact device hardware mobile password privacy private protection safety secure security telephone",
    phone_missed: "arrow call cell contact device hardware mobile telephone",
    phone_paused: "call cell contact device hardware mobile telephone wait",
    photo: "image mountains photography picture",
    photo_album: "archive bookmark image label library mountains photography picture ribbon save tag",
    photo_camera: "image photography picture",
    photo_camera_back: "image landscape mountains photography picture rear",
    photo_camera_front: "account face human image people person photography picture portrait profile user",
    photo_filter: "filters image photography picture stars",
    photo_library: "album image mountains photography picture",
    photo_size_select_actual: "image mountains photography picture",
    photo_size_select_large: "adjust album editing image library mountains photography picture",
    photo_size_select_small: "adjust album editing image large library mountains photography picture",
    php: "alphabet brackets character code css developer engineering font html letter platform symbol text type",
    piano: "instrument keyboard keys musical social",
    piano_off: "disabled enabled instrument keyboard keys musical on slash social",
    picture_as_pdf: "alphabet character document file font image letter multiple photography symbol text type",
    picture_in_picture: "cropped overlap photo position shape",
    picture_in_picture_alt: "cropped overlap photo position shape",
    pie_chart: "analytics bars data diagram infographic measure metrics statistics tracking",
    pie_chart_outline: "analytics bars data diagram infographic measure metrics statistics tracking",
    pin: "1 2 3 digit key login logout number password pattern security star symbol unlock",
    pinch: "arrows compress direction finger grasp hand navigation nip squeeze tweak",
    pin_drop: "destination direction gps location maps navigation place stop",
    pivot_table_chart: "analytics arrows bars data diagram direction drive editing grid infographic measure metrics rotate sheet statistics tracking",
    place: "destination direction location maps navigation pin point stop",
    plagiarism: "document find glass look magnifying page paper search see",
    play_arrow: "controls media music player start video",
    play_circle: "arrow controls media music video",
    play_disabled: "controls enabled media music off slash video",
    play_for_work: "arrow circle down google half",
    play_lesson: "audio bookmark digital ebook lesson multimedia play reading ribbon",
    playlist_add: "+ collection music new plus symbol task todo",
    playlist_add_check: "approve checkmark collection complete done music ok select task tick todo validate verified yes",
    playlist_add_check_circle: "album artist audio cd collection mark music record sound track",
    playlist_add_circle: "album artist audio cd check collection mark music record sound track",
    playlist_play: "arow arrow collection music",
    playlist_remove: "- collection minus music",
    plumbing: "build construction fix handyman repair tools wrench",
    plus_one: "1 add digit increase number symbol",
    podcasts: "broadcast casting network signal transmitting wireless",
    point_of_sale: "checkout cost machine merchant money payment pos retail system transaction",
    policy: "certified find glass legal look magnifying privacy private protection search security see shield verified",
    poll: "analytics barchart bars data diagram infographic measure metrics statistics survey tracking vote",
    pool: "athlete athletic beach body entertainment exercise hobby human ocean people person places sea sports swimming water",
    portable_wifi_off: "connected connection data device disabled enabled internet network offline service signal slash usage wireless",
    portrait: "account face human people person photo picture profile user",
    post_add: "+ data document drive file folders item page paper plus sheet slide text writing",
    power: "charge cord electrical online outlet plug socket",
    power_input: "dc lines supply",
    power_off: "charge cord disabled electrical enabled on outlet plug slash",
    power_settings_new: "information off save shutdown",
    precision_manufacturing: "arm automatic chain conveyor crane factory industry machinery mechanical production repairing robot supply warehouse",
    pregnant_woman: "baby birth body female human lady maternity mom mother people person user women",
    present_to_all: "arrow presentation screen share slides website",
    preview: "design eye layout reveal screen see show website window www",
    price_change: "arrows bill card cash coin commerce cost credit currency dollars down finance money online payment shopping symbol up",
    price_check: "approve bill card cash coin commerce complete cost credit currency dollars done finance mark money ok online payment select shopping symbol tick validate verified yes",
    print: "draft fax ink machine office paper printer send",
    print_disabled: "enabled off on paper printer slash",
    priority_high: "! alert attention caution danger error exclamation important mark notification symbol warning",
    privacy_tip: "alert announcement announcment assistance certified details help information private protection security service shield support verified",
    production_quantity_limits: "! alert attention bill card cart cash caution coin commerce credit currency danger dollars error exclamation important mark money notification online payment shopping symbol warning",
    propane: "gas nest",
    propane_tank: "bbq gas grill nest",
    psychology: "behavior body brain cognitive function gear head human intellectual mental mind people person preferences psychiatric science settings social therapy thinking thoughts",
    public: "country earth global globe language map network planet social space web world",
    public_off: "disabled earth enabled global globe map network on planet slash social space web world",
    publish: "arrow cloud file import submit upload",
    published_with_changes: "approve arrows check complete done inprogress loading mark ok refresh renew replace rotate select tick validate verified yes",
    push_pin: "location marker place remember save",
    qr_code: "barcode camera media product quick response smartphone urls",
    qr_code_2: "barcode camera media product quick response smartphone urls",
    qr_code_scanner: "barcode camera media product quick response smartphone urls",
    query_builder: "clock date hour minute save schedule time",
    query_stats: "analytics chart data diagram find glass infographic line look magnifying measure metrics search see statistics tracking",
    question_answer: "bubble chat comment communicate conversation converse feedback message speech talk",
    question_mark: "? assistance help information mark punctuation question support symbol",
    queue: "add collection layers multiple music playlist stack stream video",
    queue_music: "add collection playlist stream",
    queue_play_next: "+ add arrow collection desktop device display hardware monitor music new playlist plus screen steam symbol tv video",
    quickreply: "bubble chat comment communicate fast lightning message speech thunderbolt",
    quiz: "? assistance faq help information mark punctuation question support symbol test",
    radar: "detect military near network position scan",
    radio: "antenna audio device frequency hardware listen media music player signal tune",
    radio_button_checked: "application bullet circle components design form interface off point record screen selected toggle ui ux website",
    radio_button_unchecked: "bullet circle deselected form off point record toggle",
    railway_alert: "! attention automobile bike cars caution danger direction error exclamation important maps mark notification public scooter subway symbol train transportation vehicle vespa warning",
    ramen_dining: "breakfast dinner drink fastfood lunch meal noodles restaurant",
    ramp_left: "arrows directions maps navigation path route sign traffic",
    ramp_right: "arrows directions maps navigation path route sign traffic",
    rate_review: "chat comment feedback message pencil stars write",
    raw_off: "alphabet character disabled enabled font image letter original photography slash symbol text type",
    raw_on: "alphabet character disabled enabled font image letter off original photography slash symbol text type",
    read_more: "arrow text",
    receipt: "bill credit invoice paper payment sale transaction",
    receipt_long: "bill check document list paperwork record store transaction",
    recent_actors: "account avatar cards carousel contacts face human layers list people person profile thumbnail user",
    recommend: "approved circle confirm favorite gesture hand like reaction social support thumbs well",
    record_voice_over: "account face human people person profile recording sound speaking speech transcript user",
    rectangle: "four parallelograms polygons quadrilaterals recangle shape sides",
    redeem: "bill cart cash certificate coin commerce credit currency dollars giftcard money online payment present shopping",
    redo: "arrow backward forward next repeat rotate undo",
    reduce_capacity: "arrow body covid decrease down human people person social",
    refresh: "around arrows direction inprogress loading navigation refresh renew right rotate turn",
    remember_me: "Android avatar device hardware human iOS identity mobile people person phone profile tablet user",
    remove: "can delete line minus negative substract subtract trash",
    remove_circle: "allowed banned block can delete disable minus negative not substract trash",
    remove_circle_outline: "allowed banned block can delete disable minus negative not substract trash",
    remove_done: "approve check complete disabled enabled finished mark multiple off ok select slash tick yes",
    remove_from_queue: "collection desktop device display hardware list monitor screen steam television",
    remove_moderator: "certified disabled enabled off privacy private protection security shield slash verified",
    remove_red_eye: "iris looking preview see sight vision",
    remove_road: "- cancel close destination direction exit highway maps minus new no stop street symbol traffic",
    remove_shopping_cart: "card cash checkout coin commerce credit currency disabled dollars enabled off online payment slash tick",
    reorder: "format lines list stacked",
    repeat: "arrows controls media music video",
    repeat_on: "arrows controls media music video",
    repeat_one: "1 arrows controls digit media music number symbol video",
    repeat_one_on: "arrows controls digit media music number symbol video",
    replay: "arrows controls music refresh reload renew repeat retry rewind undo video",
    replay_10: "arrows controls digit music number refresh renew repeat rewind symbol ten video",
    replay_30: "arrows controls digit music number refresh renew repeat rewind symbol thirty video",
    replay_5: "arrows controls digit five music number refresh renew repeat rewind symbol video",
    replay_circle_filled: "arrows controls music refresh renew repeat video",
    reply: "arrow backward left mail message send share",
    reply_all: "arrows backward group left mail message multiple send share",
    report: "! alert attention caution danger error exclamation important mark notification octagon symbol warning",
    report_gmailerrorred: "! alert attention caution danger exclamation important mark notification octagon symbol warning",
    report_off: "! alert attention caution danger disabled enabled error exclamation important mark notification octagon offline slash symbol warning",
    report_problem: "! alert announcement attention caution danger error exclamation feedback important mark notification symbol triangle warning",
    request_quote: "bill card cash coin commerce cost credit currency dollars finance money online payment price shopping symbol",
    reset_tv: "arrow device hardware monitor television",
    restart_alt: "around arrow inprogress loading reboot refresh renew repeat reset",
    restaurant: "breakfast cutlery dining dinner eat food fork knife local lunch meal places spoon utensils",
    restaurant_menu: "book dining eat food fork knife local meal spoon",
    restore: "arrow backwards clock date history refresh renew reverse rotate schedule time turn undo",
    restore_from_trash: "arrow backwards can clock date delete garbage history refresh remove renew reverse rotate schedule time turn up",
    restore_page: "arrow data doc file history paper refresh rotate sheet storage undo web",
    reviews: "bubble chat comment communicate feedback message rate rating recommendation speech",
    rice_bowl: "dinner food lunch meal restaurant",
    ring_volume: "calling cell contact device hardware incoming mobile ringer sound telephone",
    r_mobiledata: "alphabet character font letter symbol text type",
    rocket: "spaceship",
    rocket_launch: "spaceship takeoff",
    roller_shades: "blinds cover curtains nest open shutter sunshade",
    roller_shades_closed: "blinds cover curtains nest shutter sunshade",
    roofing: "architecture building chimney construction estate home house real residence residential service shelter",
    room: "destination direction gps location maps marker pin place spot stop",
    room_preferences: "building doorway entrance gear home house interior office open settings",
    room_service: "alert bell concierge delivery hotel notify",
    rotate_90_degrees_ccw: "arrows direction editing image photo turn",
    rotate_90_degrees_cw: "arrows ccw direction editing image photo turn",
    rotate_left: "around arrow circle direction inprogress loading refresh reload renew reset turn",
    rotate_right: "around arrow circle direction inprogress loading refresh renew turn",
    roundabout_left: "arrows directions maps navigation path route sign traffic",
    roundabout_right: "arrows directions maps navigation path route sign traffic",
    rounded_corner: "adjust edit shape square transform",
    route: "directions maps path sign traffic",
    router: "box cable connection device hardware internet network signal wifi",
    rowing: "activity boat body canoe human people person sports water",
    rss_feed: "application blog connection data internet network service signal website wifi wireless",
    rsvp: "alphabet character font invitation invite letter plaît respond répondez sil symbol text type vous",
    rtt: "call real rrt text time",
    rule: "approve check done incomplete line mark missing no ok select tick validate verified wrong x yes",
    rule_folder: "approve cancel check close complete data document done drive exit file mark no ok remove select sheet slide storage tick validate verified yes",
    run_circle: "body exercise human people person running",
    running_with_errors: "! alert attention caution danger duration exclamation important mark notification processing symbol time warning",
    rv_hookup: "arrow attach automobile automotive back cars connect direction left maps public right trailer transportation travel truck van vehicle",
    safety_divider: "apart distance separate social space",
    sailing: "entertainment fishing hobby ocean sailboat sea social sports travel water",
    sanitizer: "bacteria bottle clean covid disinfect germs pump",
    satellite: "bluetooth connection connectivity data device image internet landscape location maps mountains network photography picture scan service signal symbol wifi wireless--",
    satellite_alt: "alternative artificial communication space station television",
    save: "data diskette document drive file floppy multimedia storage write",
    save_alt: "arrow diskette document down file floppy multimedia write",
    save_as: "compose create data disk document draft drive editing file floppy input multimedia pencil storage write writing",
    saved_search: "find glass important look magnifying marked see star",
    savings: "bank bill card cash coin commerce cost credit currency dollars finance money online payment piggy symbol",
    scale: "measure monitor weight",
    scanner: "copy device hardware machine",
    scatter_plot: "analytics bars chart circles data diagram dot infographic measure metrics statistics tracking",
    schedule: "calendar clock date mark save time",
    schedule_send: "calendar clock date email letter remember share time",
    schema: "analytics chart data diagram flow infographic measure metrics statistics tracking",
    school: "academy achievement cap class college education graduation hat knowledge learning university",
    science: "beaker chemical chemistry experiment flask glass laboratory research tube",
    score: "2k alphabet analytics bars character chart data diagram digit font infographic letter measure metrics number statistics symbol text tracking type",
    screen_lock_landscape: "Android device hardware iOS mobile phone rotate security tablet",
    screen_lock_portrait: "Android device hardware iOS mobile phone rotate security tablet",
    screen_lock_rotation: "Android arrow device hardware iOS mobile phone rotate tablet turn",
    screen_rotation: "Android arrow device hardware iOS mobile phone rotate tablet turn",
    screen_search_desktop: "Android arrow device hardware iOS lock monitor rotate web",
    screen_share: "Android arrow cast chrome device display hardware iOS laptop mac mirror monitor steam streaming web window",
    screenshot: "Android cell crop device hardware iOS mobile phone tablet",
    screenshot_monitor: "Android chrome desktop device display hardware iOS mac screengrab web window",
    sd: "alphabet camera card character data device digital drive flash font image letter memory photo secure symbol text type",
    sd_card: "camera digital memory photos secure storage",
    sd_card_alert: "! attention camera caution danger digital error exclamation important mark memory notification photos secure storage symbol warning",
    sd_storage: "camera card data digital memory microsd secure",
    search: "filter find glass look magnifying see up",
    search_off: "cancel close disabled enabled find glass look magnifying on see slash stop x",
    security: "certified privacy private protection shield verified",
    security_update: "Android arrow device download hardware iOS mobile phone tablet",
    security_update_good: "Android checkmark device hardware iOS mobile ok phone tablet tick",
    security_update_warning: "! Android alert attention caution danger device download error exclamation hardware iOS important mark mobile notification phone symbol tablet",
    segment: "alignment fonts format lines list paragraph part piece rules style text",
    select_all: "selection square tool",
    self_improvement: "body calm care chi human meditate meditation people person relax sitting wellbeing yoga zen",
    sell: "bill card cart cash coin commerce credit currency dollars money online payment price shopping tag",
    send: "chat email message paper plane reply right share telegram",
    send_and_archive: "arrow download email letter save share",
    send_time_extension: "deliver dispatch envelop mail message schedule",
    send_to_mobile: "Android arrow device export forward hardware iOS phone right share tablet",
    sensors: "connection network scan signal wireless",
    sensors_off: "connection disabled enabled network scan signal slash wireless",
    sentiment_dissatisfied: "angry disappointed dislike emoji emoticon emotions expressions face feelings frown mood person sad smiley survey unhappy unsatisfied upset",
    sentiment_neutral: "emotionless emotions expressions face feelings indifference mood okay person survey",
    sentiment_satisfied: "emoji emoticon emotions expressions face feelings glad happiness happy like mood person pleased smiley smiling survey",
    sentiment_satisfied_alt: "account emoji face happy human people person profile smile user",
    sentiment_very_dissatisfied: "angry disappointed dislike emoji emoticon emotions expressions face feelings mood person sad smiley sorrow survey unhappy unsatisfied upset",
    sentiment_very_satisfied: "emoji emoticon emotions expressions face feelings glad happiness happy like mood person pleased smiley smiling survey",
    set_meal: "chopsticks dinner fish food lunch restaurant teishoku",
    settings: "application change details gear information options personal service",
    settings_accessibility: "body details human information people personal preferences profile user",
    settings_applications: "change details gear information options personal save service",
    settings_backup_restore: "arrow backwards history refresh reverse rotate time undo",
    settings_bluetooth: "connection connectivity device network signal symbol wifi",
    settings_brightness: "dark filter light mode sun",
    settings_cell: "Android cellphone device hardware iOS mobile tablet",
    settings_ethernet: "arrows brackets computer connection connectivity dots internet network parenthesis wifi",
    settings_input_antenna: "airplay arrows computer connection connectivity dots internet network screencast stream wifi wireless",
    settings_input_component: "audio av cables connection connectivity internet plugs points video wifi",
    settings_input_composite: "cable component connection connectivity plugs points",
    settings_input_hdmi: "cable connection connectivity definition high plugin points video wire",
    settings_input_svideo: "cable connection connectivity definition plugin plugs points standard svideo,",
    settings_overscan: "arrows expand image photo picture",
    settings_phone: "call cell contact device hardware mobile telephone",
    settings_power: "information off save shutdown",
    settings_remote: "bluetooth connection connectivity control device signal wifi wireless",
    settings_suggest: "change details gear options recommendation service suggestion system",
    settings_system_daydream: "backup cloud drive storage",
    settings_voice: "microphone recorder speaker",
    share: "android connect contect link multimedia multiple network options send shared sharing social",
    share_location: "destination direction gps maps pin place stop tracking",
    shield: "certified privacy private protection secure security verified",
    shop: "arrow bag bill briefcase buy card cart cash coin commerce credit currency dollars google money online payment play purchase shopping store",
    shop_2: "add arrow buy cart google play purchase shopping",
    shopping_bag: "bill business buy card cart cash coin commerce credit currency dollars money online payment storefront",
    shopping_basket: "add bill buy card cart cash checkout coin commerce credit currency dollars money online payment purchase",
    shopping_cart: "add bill buy card cash checkout coin commerce credit currency dollars money online payment purchase",
    shopping_cart_checkout: "arrow cash coin commerce currency dollars money online payment right",
    shop_two: "add arrow briefcase buy cart google play purchase shopping",
    shortcut: "arrow direction forward right",
    short_text: "brief comment document lines note write writing",
    show_chart: "analytics bars chart data diagram infographic line measure metrics presentation show statistics stock tracking",
    shower: "bathroom closet home house place plumbing sprinkler wash water wc",
    shuffle: "arrows controls music random video",
    shuffle_on: "arrows controls music random video",
    shutter_speed: "aperture camera duration image lens photography photos picture setting stop timer watch",
    sick: "covid discomfort emotions expressions face feelings fever flu ill mood pain person survey upset",
    signal_cellular_0_bar: "data internet mobile network phone speed wifi wireless",
    signal_cellular_4_bar: "data internet mobile network phone speed wifi wireless",
    signal_cellular_alt: "analytics bar chart data diagram infographic internet measure metrics mobile network phone statistics tracking wifi wireless",
    signal_cellular_connected_no_internet_0_bar: "! alert attention caution danger data error exclamation important mark mobile network notification phone symbol warning wifi wireless",
    signal_cellular_connected_no_internet_4_bar: "! alert attention caution danger data error exclamation important mark mobile network notification phone symbol warning wifi wireless",
    signal_cellular_nodata: "internet mobile network offline phone quit wifi wireless x",
    signal_cellular_no_sim: "camera card chip device disabled enabled memory network offline phone slash storage",
    signal_cellular_null: "data internet mobile network phone wifi wireless",
    signal_cellular_off: "data disabled enabled internet mobile network offline phone slash wifi wireless",
    signal_wifi_bad: "bar cancel cellular close data exit internet mobile network no phone quit remove stop wireless",
    signal_wifi_off: "cellular data disabled enabled internet mobile network phone slash speed wireless",
    signal_wifi_statusbar_null: "cellular data internet mobile network phone speed wireless",
    signpost: "arrow direction left maps right signal signs street traffic",
    sim_card: "camera chip device memory network phone storage",
    sim_card_alert: "! attention camera caution danger digital error exclamation important mark memory notification photos sd secure storage symbol warning",
    sim_card_download: "arrow camera chip device memory phone storage",
    single_bed: "bedroom double furniture home hotel house king night pillows queen rest sleep twin",
    sip: "alphabet call character dialer font initiation internet letter over phone protocol routing session symbol text type voice",
    skateboarding: "athlete athletic body entertainment exercise hobby human people person skateboarder social sports",
    skip_next: "arrow back controls forward music play previous transport video",
    skip_previous: "arrow backward controls forward music next play transport video",
    sledding: "athlete athletic body entertainment exercise hobby human people person sledge snow social sports travel winter",
    slideshow: "movie photos play presentation square video view",
    slow_motion_video: "arrow circle controls music play speed time",
    smart_button: "action auto components composer function interface special stars ui ux website",
    smart_display: "airplay chrome connect device screencast stream television tv video wireless",
    smartphone: "Android call cell chat device hardware iOS mobile tablet text",
    smart_screen: "Android airplay cell connect device hardware iOS mobile phone screencast stream tablet video",
    smart_toy: "games robot",
    smoke_free: "cigarette disabled enabled never no off places prohibited slash smoking tobacco warning zone",
    smoking_rooms: "allowed cigarette places smoke tobacco zone",
    sms: "3 bubble chat comment communication conversation dots message more service speech three",
    sms_failed: "! alert attention bubbles caution chat comment communication conversation danger error exclamation important mark message notification service speech symbol warning",
    snippet_folder: "data document drive file sheet slide storage",
    snooze: "alarm bell clock duration notification set timer watch",
    snowboarding: "athlete athletic body entertainment exercise hobby human people person social sports travel winter",
    snowmobile: "automobile car direction skimobile social sports transportation travel vehicle winter",
    snowshoeing: "body human people person sports travel walking winter",
    soap: "bathroom clean fingers gesture hand wash wc",
    social_distance: "6 apart body ft human people person space",
    solar_power: "eco energy heat nest sunny",
    sort: "filter find lines list organize",
    sort_by_alpha: "alphabetize az by character font letters list order organize symbol text type",
    soup_kitchen: "breakfast brunch dining food lunch meal",
    source: "code composer content creation data document file folder mode storage view",
    south: "arrow directional down maps navigation",
    south_america: "america continent landscape place region south",
    south_east: "arrow directional down maps navigation right",
    south_west: "arrow directional down left maps navigation",
    spa: "aromatherapy flower healthcare leaf massage meditation nature petals places relax wellbeing wellness",
    space_bar: "keyboard line",
    speaker: "audio box electronic loud music sound stereo system video",
    speaker_group: "audio box electronic loud multiple music sound stereo system video",
    speaker_notes: "bubble cards chat comment communicate format list message speech text",
    speaker_notes_off: "bubble cards chat comment communicate disabled enabled format list message on slash speech text",
    speaker_phone: "Android cell device hardware iOS mobile sound tablet volume",
    speed: "arrow clock controls dial fast gauge measure motion music slow speedometer test velocity video",
    spellcheck: "alphabet approve character checkmark edit font letter ok processor select symbol text tick type word write yes",
    splitscreen: "grid layout multitasking two",
    spoke: "connection network radius",
    sports: "athlete athletic basketball blowing coach entertainment exercise game hobby instrument live referee soccer social sound trophy warning whistle",
    sports_bar: "alcohol beer drink liquor pint places pub",
    sports_baseball: "athlete athletic entertainment exercise game hobby social",
    sports_basketball: "athlete athletic entertainment exercise game hobby social",
    sports_cricket: "athlete athletic ball bat entertainment exercise game hobby social",
    sports_esports: "controller entertainment gamepad gaming hobby online playstation social video xbox",
    sports_football: "american athlete athletic entertainment exercise game hobby social",
    sports_golf: "athlete athletic ball club entertainment exercise game golfer golfing hobby social",
    sports_handball: "athlete athletic body entertainment exercise game hobby human people person social",
    sports_hockey: "athlete athletic entertainment exercise game hobby ice social sticks",
    sports_kabaddi: "athlete athletic body combat entertainment exercise fighting game hobby human judo martial people person social wrestle wrestling",
    sports_martial_arts: "athlete athletic entertainment exercise hobby human karate people person social",
    sports_mma: "arts athlete athletic boxing combat entertainment exercise fighting game glove hobby martial mixed social",
    sports_motorsports: "athlete athletic automobile bike drive driving entertainment helmet hobby motorcycle protect social vehicle",
    sports_rugby: "athlete athletic ball entertainment exercise game hobby social",
    sports_score: "destination flag goal",
    sports_soccer: "athlete athletic entertainment exercise football game hobby social",
    sports_tennis: "athlete athletic ball bat entertainment exercise game hobby racket social",
    sports_volleyball: "athlete athletic entertainment exercise game hobby social",
    square: "draw four quadrangle shape sides",
    square_foot: "construction feet inches length measurement ruler school set tools",
    ssid_chart: "graph lines network wifi",
    stacked_bar_chart: "analytics chart-chart data diagram infographic measure metrics statistics tracking",
    stacked_line_chart: "analytics data diagram infographic measure metrics statistics tracking",
    stadium: "activity amphitheater arena coliseum event local star things ticket",
    stairs: "down staircase up",
    star: "best bookmark favorite highlight ranking rate rating save toggle",
    star_border: "best bookmark favorite highlight outline ranking rate rating save toggle",
    star_half: "0.5 1/2 achievement bookmark favorite highlight important marked ranking rate rating reward saved shape special toggle",
    star_outline: "bookmark favorite half highlight ranking rate rating save toggle",
    star_rate: "achievement bookmark favorite highlight important marked ranking rating reward saved shape special",
    stars: "achievement bookmark circle favorite highlight important like love marked ranking rate rating reward saved shape special",
    start: "arrow keyboard next right",
    stay_current_landscape: "Android device hardware iOS mobile phone tablet",
    stay_current_portrait: "Android device hardware iOS mobile phone tablet",
    stay_primary_landscape: "Android current device hardware iOS mobile phone tablet",
    stay_primary_portrait: "Android current device hardware iOS mobile phone tablet",
    sticky_note_2: "bookmark message paper text writing",
    stop: "arrow controls music pause player square video",
    stop_circle: "controls music pause play square video",
    stop_screen_share: "Android arrow cast chrome device disabled display enabled hardware iOS laptop mac mirror monitor offline slash steam streaming web window",
    storage: "computer database drive memory network server",
    store: "bill building business buy card cash coin company credit currency dollars e-commerce market money online payment purchase shopping storefront",
    storefront: "business buy cafe commerce market merchant places restaurant retail sell shopping stall",
    store_mall_directory: "building",
    storm: "forecast hurricane temperature twister weather wind",
    straight: "arrows directions maps navigation path route sign traffic up",
    straighten: "length measurement piano ruler size",
    stream: "cast connected feed live network signal wireless",
    streetview: "gps location maps",
    strikethrough_s: "alphabet character cross doc editing editor font letter out spreadsheet styles symbol text type writing",
    stroller: "baby care carriage children infant kid newborn toddler young",
    style: "booklet cards filters options tags",
    subdirectory_arrow_left: "arrow down navigation",
    subdirectory_arrow_right: "arrow down navigation",
    subject: "alignment document email full justify lines list note text writing",
    subscript: "2 doc editing editor gmail novitas spreadsheet style symbol text writing",
    subscriptions: "enroll media order playlist queue signup subscribe youtube",
    subtitles: "accessibility accessible captions character closed decoder language media movies translate tv",
    subtitles_off: "accessibility accessible caption closed disabled enabled language slash translate video",
    subway: "automobile bike cars maps metro rail scooter train transportation travel tunnel underground vehicle vespa",
    summarize: "document list menu note report summary",
    superscript: "2 doc editing editor gmail novitas spreadsheet style symbol text writing",
    supervised_user_circle: "account avatar control face human parental parents people person profile supervisor",
    supervisor_account: "administrator avatar control face human parental parents people person profile supervised user",
    support: "assist help lifebuoy rescue safety",
    support_agent: "care customer face headphone person representative service",
    surfing: "athlete athletic beach body entertainment exercise hobby human people person sea social sports summer water",
    surround_sound: "audio circle signal speaker system volume volumn wireless",
    swap_calls: "arrows device direction mobile share",
    swap_horiz: "arrows back direction forward horizontal",
    swap_horizontal_circle: "arrows back direction forward",
    swap_vert: "arrows back direction down navigation up vertical",
    swap_vertical_circle: "arrows back direction down horizontal up",
    swipe: "arrows fingers gesture hands touch",
    swipe_down: "arrows direction disable enable finger hands hit navigation strike swing swpie take",
    swipe_down_alt: "arrows direction disable enable finger hands hit navigation strike swing swpie take",
    swipe_left: "arrows finger hand hit navigation reject strike swing take",
    swipe_left_alt: "arrows finger hand hit navigation reject strike swing take",
    swipe_right: "accept arrows direction finger hands hit navigation strike swing swpie take",
    swipe_right_alt: "accept arrows direction finger hands hit navigation strike swing swpie take",
    swipe_up: "arrows direction disable enable finger hands hit navigation strike swing swpie take",
    swipe_up_alt: "arrows direction disable enable finger hands hit navigation strike swing swpie take",
    swipe_vertical: "arrows direction finger hands hit navigation strike swing swpie take verticle",
    switch_access_shortcut: "arrows direction navigation new north star symbol up",
    switch_access_shortcut_add: "+ arrows direction navigation new north plus star symbol up",
    switch_account: "choices face human multiple options people person profile social user",
    switch_camera: "arrows photography picture",
    switch_left: "arrows directional navigation toggle",
    switch_right: "arrows directional navigation toggle",
    switch_video: "arrows camera photography videos",
    sync: "360 around arrows direction inprogress loading refresh renew rotate turn",
    sync_alt: "arrows horizontal internet technology update wifi",
    sync_disabled: "360 around arrows direction enabled inprogress loading off refresh renew rotate slash turn",
    sync_lock: "around arrows locked password privacy private protection renew rotate safety secure security turn",
    sync_problem: "! 360 alert around arrows attention caution danger direction error exclamation important inprogress loading mark notification refresh renew rotate symbol turn warning",
    system_security_update: "Android arrow cell device down hardware iOS mobile phone tablet",
    system_security_update_good: "Android approve cell check complete device done hardware iOS mark mobile ok phone select tablet tick validate verified yes",
    system_security_update_warning: "! Android alert attention caution cell danger device error exclamation hardware iOS important mark mobile notification phone symbol tablet",
    system_update: "Android arrows cell device direction download hardware iOS install mobile phone tablet",
    system_update_alt: "arrow download export",
    tab: "browser computer documents folder internet tabs website windows",
    table_chart: "analytics bars data diagram grid infographic measure metrics statistics tracking",
    table_rows: "grid layout lines stacked",
    tablet: "Android device hardware iOS ipad mobile web",
    tablet_android: "device hardware iOS ipad mobile web",
    tablet_mac: "Android apple device hardware iOS ipad mac mobile tablet web",
    table_view: "format grid group layout multiple",
    tab_unselected: "browser computer documents folder internet tabs website windows",
    tag: "hashtag key media number pound social trend",
    tag_faces: "emoji emotion happy satisfied smile",
    takeout_dining: "box container delivery food meal restaurant",
    tap_and_play: "Android cell connection device hardware iOS internet mobile network nfc phone signal tablet to wifi wireless",
    tapas: "appetizer brunch dinner food lunch restaurant snack",
    task: "approve check complete data document done drive file folders mark ok page paper select sheet slide tick validate verified writing yes",
    task_alt: "approve check circle complete done mark ok select tick validate verified yes",
    taxi_alert: "! attention automobile cab cars caution danger direction error exclamation important lyft maps mark notification public symbol transportation uber vehicle warning yellow",
    terminal: "application code emulator program software",
    terrain: "geography landscape mountain",
    text_decrease: "- alphabet character font letter minus remove resize subtract symbol type",
    text_fields: "T add alphabet character font input letter symbol type",
    text_format: "A alphabet character font letter square style symbol type",
    text_increase: "+ add alphabet character font letter new plus resize symbol type",
    text_rotate_up: "A alphabet arrow character field font letter move symbol type",
    text_rotate_vertical: "A alphabet arrow character down field font letter move symbol type verticle",
    text_rotation_angledown: "A alphabet arrow character field font letter move rotate symbol type",
    text_rotation_angleup: "A alphabet arrow character field font letter move rotate symbol type",
    text_rotation_down: "A alphabet arrow character field font letter move rotate symbol type",
    text_rotation_none: "A alphabet arrow character field font letter move rotate symbol type",
    textsms: "bubble chat comment communicate dots feedback message speech",
    text_snippet: "data document file notes storage writing",
    texture: "diagonal lines pattern stripes",
    theater_comedy: "broadway event movie musical places show standup tour watch",
    theaters: "film media movies photography showtimes video watch",
    thermostat: "forecast temperature weather",
    thermostat_auto: "A celsius fahrenheit temperature thermometer",
    thumb_down: "dislike downvote favorite fingers gesture hands ranking rate rating reject up",
    thumb_down_alt: "bad decline disapprove dislike feedback hand hate negative no reject social veto vote",
    thumb_down_off_alt: "bad decline disapprove dislike favorite feedback filled fingers gesture hands hate negative no ranking rate rating reject sad social veto vote",
    thumbs_up_down: "dislike favorite fingers gesture hands rate rating vote",
    thumb_up: "approve dislike down favorite fingers gesture hands ranking rate rating success upvote",
    thumb_up_alt: "agreed approved confirm correct favorite feedback good hand happy like okay positive satisfaction social success vote yes",
    thumb_up_off_alt: "agreed approved confirm correct favorite feedback fingers gesture good hands happy like okay positive ranking rate rating satisfaction social vote yes",
    timelapse: "duration motion photo timer video",
    timeline: "analytics chart data graph history line movement points tracking trending zigzag zigzap",
    timer: "alarm alart bell clock disabled duration enabled notification off slash stopwatch wait",
    timer_10: "digits duration numbers seconds",
    timer_10_select: "alphabet camera character digit font letter number seconds symbol text type",
    timer_3: "digits duration numbers seconds",
    timer_3_select: "alphabet camera character digit font letter number seconds symbol text type",
    timer_off: "alarm alart bell clock disabled duration enabled notification slash stopwatch",
    time_to_leave: "automobile cars destination direction drive estimate eta maps public transportation travel trip vehicle",
    tips_and_updates: "alert announcement electricity idea information lamp lightbulb stars",
    title: "T alphabet character font header letter subject symbol text type",
    toc: "content format lines list reorder stacked table text titles",
    today: "agenda calendar date event mark month range remember reminder schedule time week",
    toggle_off: "application components configuration control design disable inable inactive interface selection settings slider switch ui ux website",
    toggle_on: "application components configuration control design disable inable inactive interface off selection settings slider switch ui ux website",
    token: "badge hexagon mark shield sign symbol",
    toll: "bill booth card cash circles coin commerce credit currency dollars highway money online payment ticket",
    tonality: "circle editing filter image photography picture",
    topic: "data document drive file folder sheet slide storage",
    tornado: "crisis disaster natural rain storm weather wind",
    touch_app: "arrow command fingers gesture hand press swipe tap",
    tour: "destination flag places travel visit",
    toys: "car fan games kids windmill",
    track_changes: "bullseye circle evolve lines movement radar rotate shift target",
    traffic: "direction light maps signal street",
    train: "automobile cars direction maps public rail subway transportation vehicle",
    tram: "automobile cars direction maps public rail subway train transportation vehicle",
    transfer_within_a_station: "arrows body direction human left maps people person public right route stop transit transportation vehicle walk",
    transform: "adjust crop editing image photo picture",
    transgender: "female lgbt neutral neutrual social symbol",
    transit_enterexit: "arrow direction maps navigation route transportation",
    translate: "alphabet language letter speaking speech text translator words",
    travel_explore: "earth find glass global globe look magnifying map network planet search see social space web world",
    trending_down: "analytics arrow change chart data diagram infographic measure metrics movement rate rating sale statistics tracking",
    trending_flat: "arrow change chart data graph metric movement rate right tracking",
    trending_up: "analytics arrow change chart data diagram infographic measure metrics movement rate rating statistics tracking",
    trip_origin: "circle departure",
    try: "bookmark bubble chat comment communicate favorite feedback highlight important marked message saved shape special speech star",
    tty: "call cell contact deaf device hardware impaired mobile speech talk telephone text",
    tune: "adjust editing options settings sliders",
    tungsten: "electricity indoor lamp lightbulb setting",
    turned_in: "archive bookmark favorite item label library reading remember ribbon save submit tag",
    turned_in_not: "archive bookmark favorite item label library outline reading remember ribbon save submit tag",
    turn_left: "arrows directions maps navigation path route sign traffic",
    turn_right: "arrows directions maps navigation path route sign traffic",
    turn_sharp_left: "arrows directions maps navigation path route sign traffic",
    turn_sharp_right: "arrows directions maps navigation path route sign traffic",
    turn_slight_left: "arrows directions maps navigation path right route sign traffic",
    turn_slight_right: "arrows directions maps navigation path route sharp sign traffic",
    tv: "device display linear living monitor room screencast stream television video wireless",
    tv_off: "Android chrome desktop device disabled enabled hardware iOS mac monitor slash television web window",
    two_wheeler: "automobile bicycle cars direction maps moped motorbike motorcycle public ride riding scooter transportation travel twom vehicle wheeler wheels",
    umbrella: "beach protection rain sunny",
    unarchive: "arrow inbox mail store undo up",
    undo: "arrow backward mail previous redo repeat rotate",
    unfold_less: "arrows chevron collapse direction expandable inward list navigation up",
    unfold_more: "arrows chevron collapse direction down expandable list navigation",
    unpublished: "approve check circle complete disabled done enabled mark off ok select slash tick validate verified yes",
    unsubscribe: "cancel close email envelop esubscribe message newsletter off remove send",
    upcoming: "alarm calendar mail message notification",
    update: "arrow backwards clock forward future history load refresh reverse rotate schedule time",
    update_disabled: "arrow backwards clock enabled forward history load off on refresh reverse rotate schedule slash time",
    upgrade: "arrow export instal line replace update",
    upload: "arrows download drive",
    upload_file: "arrow data document download drive folders page paper sheet slide writing",
    usb: "cable connection device wire",
    usb_off: "cable connection device wire",
    u_turn_left: "arrows directions maps navigation path route sign traffic u-turn",
    u_turn_right: "arrows directions maps navigation path route sign traffic u-turn",
    vaccines: "aid covid doctor drug emergency hospital immunity injection medical medication medicine needle pharmacy sick syringe vaccination vial",
    verified: "approve badge burst check complete done mark ok select star tick validate yes",
    verified_user: "approve audit certified checkmark complete done ok privacy private protection security select shield tick validate yes",
    vertical_align_bottom: "alignment arrow doc down editing editor spreadsheet text type writing",
    vertical_align_center: "alignment arrow doc down editing editor spreadsheet text type up writing",
    vertical_align_top: "alignment arrow doc editing editor spreadsheet text type up writing",
    vertical_shades: "blinds cover curtains nest open shutter sunshade",
    vertical_shades_closed: "blinds cover curtains nest roller shutter sunshade",
    vertical_split: "design format grid layout paragraph text website writing",
    vibration: "Android alert cell device hardware iOS mobile mode motion notification phone silence silent tablet vibrate",
    video_call: "+ add camera chat conference filming hardware image motion new picture plus screen symbol videography",
    videocam: "camera chat conference filming hardware image motion picture screen videography",
    video_camera_back: "image landscape mountains photography picture rear",
    video_camera_front: "account face human image people person photography picture profile user",
    videocam_off: "camera chat conference disabled enabled filming hardware image motion offline picture screen slash videography",
    video_file: "camera document filming hardware image motion picture videography",
    videogame_asset: "console controller device gamepad gaming nintendo playstation xbox",
    videogame_asset_off: "console controller device disabled enabled gamepad gaming playstation slash",
    video_label: "device item screen window",
    video_library: "arrow collection play",
    video_settings: "change details gear information options play screen service window",
    video_stable: "filming recording setting stability taping",
    view_agenda: "blocks cards design format grid layout website,stacked",
    view_array: "blocks design format grid layout website",
    view_carousel: "banner blocks cards design format grid images layout website",
    view_column: "blocks design format grid layout vertical website",
    view_comfy: "grid layout pattern squares",
    view_comfy_alt: "cozy design format layout web",
    view_compact: "grid layout pattern squares",
    view_compact_alt: "dense design format layout web",
    view_cozy: "comfy design format layout web",
    view_day: "blocks calendar cards carousel design format grid layout website week",
    view_headline: "blocks design format grid layout paragraph text website",
    view_in_ar: "3d augmented cube daydream headset reality square vr",
    view_kanban: "grid layout pattern squares",
    view_list: "blocks design format grid layout lines reorder stacked title website",
    view_module: "blocks design format grid layout reorder squares stacked title website",
    view_quilt: "blocks design format grid layout reorder squares stacked title website",
    view_sidebar: "design format grid layout web",
    view_stream: "blocks design format grid layout lines list reorder stacked title website",
    view_timeline: "grid layout pattern squares",
    view_week: "bars blocks columns day design format grid layout website",
    vignette: "border editing effect filter gradient image photography setting",
    villa: "architecture beach estate home house maps place real residence residential stay traveling vacation",
    visibility: "eye on password preview reveal see shown visability",
    visibility_off: "disabled enabled eye hidden invisible on password reveal see show slash view visability",
    voice_chat: "bubble camera comment communicate facetime feedback message speech video",
    voicemail: "call device message missed mobile phone recording",
    voice_over_off: "account disabled enabled face human people person profile recording slash speaking speech transcript user",
    volume_down: "audio av control music quieter shh soft sound speaker tv",
    volume_mute: "audio control music sound speaker tv",
    volume_off: "audio av control disabled enabled low music mute slash sound speaker tv",
    volume_up: "audio control music sound speaker tv",
    volunteer_activism: "donation fingers gesture giving hands heart love sharing",
    vpn_key: "login network passcode password register security signin signup unlock",
    vpn_key_off: "[offline] disabled enabled network on passcode password slash unlock",
    vpn_lock: "earth globe locked network password privacy private protection safety secure security virtual world",
    vrpano: "angle image landscape mountains panorama photography picture view wide",
    wallpaper: "background image landscape photography picture",
    warehouse: "garage industry manufacturing storage",
    warning: "! alert announcement attention caution danger error exclamation feedback important mark notification problem symbol triangle",
    warning_amber: "! alert attention caution danger error exclamation important mark notification symbol triangle",
    wash: "bathroom clean fingers gesture hand wc",
    watch: "Android clock gadget iOS smartwatch time vr wearables web wristwatch",
    watch_later: "clock date hour minute schedule time",
    watch_off: "Android clock close gadget iOS shut time vr wearables web wristwatch",
    water: "aqua beach lake ocean river waves weather",
    water_damage: "architecture building droplet estate house leak plumbing real residence residential shelter",
    waterfall_chart: "analytics bar data diagram infographic measure metrics statistics tracking",
    waves: "beach lake ocean pool river sea swim water",
    wb_auto: "A W alphabet automatic balance character editing font image letter photography symbol text type white wp",
    wb_cloudy: "balance editing white wp",
    wb_incandescent: "balance bright editing lamp lightbulb lighting settings white wp",
    wb_iridescent: "balance bright editing lighting settings white wp",
    wb_shade: "balance house lighting white",
    wb_sunny: "balance bright lighting weather white",
    wc: "bathroom closet female gender man person restroom toilet unisex wash water women",
    web: "blocks browser internet page screen website www",
    web_asset: "-website application browser design desktop download image interface internet layout screen ui ux video window www",
    web_asset_off: "browser disabled enabled internet on screen slash webpage website windows www",
    webhook: "api developer development enterprise software",
    weekend: "chair couch furniture home living lounge relax room seat",
    west: "arrow directional left maps navigation",
    whatshot: "arrow circle direction fire frames round trending",
    wheelchair_pickup: "accessibility accessible body handicap help human person",
    where_to_vote: "approve ballot check complete destination direction done election location maps mark ok pin place poll select stop tick validate verified yes",
    widgets: "app blocks box menu setting squares ui",
    wifi: "connection data internet network scan service signal wireless",
    wifi_calling: "cell connection connectivity contact device hardware mobile signal telephone wireless",
    wifi_calling_3: "cellular data internet mobile network phone speed wireless",
    wifi_channel: "(scan) [cellular connection data internet mobile] network service signal wireless",
    wifi_find: "(scan) [cellular connection data detect discover glass internet look magnifying mobile] network notice search service signal wireless",
    wifi_lock: "cellular connection data internet locked mobile network password privacy private protection safety secure security service signal wireless",
    wifi_off: "connection data disabled enabled internet network offline scan service signal slash wireless",
    wifi_password: "(scan) [cellular connection data internet lock mobile] network secure service signal wireless",
    wifi_protected_setup: "around arrows rotate",
    wifi_tethering: "cellular connection data internet mobile network phone scan service signal speed wireless",
    wifi_tethering_off: "cellular connection data disabled enabled internet mobile network offline phone scan service signal slash speed wireless",
    window: "close glass grid home house interior layout outside",
    wind_power: "eco energy nest windy",
    wine_bar: "alcohol cocktail cup drink glass liquor",
    work: "-briefcase baggage business job suitcase",
    work_history: "arrow backwards baggage briefcase business clock date job refresh renew reverse rotate schedule suitcase time turn",
    work_off: "baggage briefcase business disabled enabled job on slash suitcase",
    work_outline: "baggage briefcase business job suitcase",
    workspace_premium: "certification degree ecommerce guarantee medal permit ribbon verification",
    workspaces: "circles collaboration dot filled group team",
    wrap_text: "arrow doc editing editor spreadsheet type write writing",
    wrong_location: "cancel close destination direction exit maps no pin place quit remove stop",
    wysiwyg: "composer mode screen software system text view visibility website window",
    yard: "backyard flower garden home house nature pettle plants",
    youtube_searched_for: "arrow backwards find glass history inprogress loading look magnifying refresh renew restore reverse rotate see yt",
    zoom_in: "bigger find glass grow look magnifier magnifying plus scale search see size",
    zoom_in_map: "arrows destination location maps move place stop",
    zoom_out: "find glass look magnifier magnifying minus negative scale search see size smaller",
    zoom_out_map: "arrows destination location maps move place stop"
};
